import { defaultTheme } from 'react-admin';
import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';

export const darkTheme = {
    ...defaultTheme,
    palette: {
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#FBBA72',
        },
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                color: '#ffffffb3',
                backgroundColor: '#616161e6',
            },
        },
    },
};

export const myTheme = {
    ...defaultTheme,
    palette: {
        primary: grey,
        secondary: red,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
    sidebar: {
        width: 200, // The default value is 240
        //closedWidth: 70, // The default value is 55
    },
    components: {
        ...defaultTheme.components,
        RaButton: {
            styleOverrides: {
                root: {
                    backgroundColor: red,
                }
            }
        }
    }
};
