import * as React from 'react';

import { Grid } from '@mui/material';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    BooleanField,
    NumberField,
    RichTextField
} from 'react-admin';

import { ShowActions } from 'admin/components';

import CustomTitle from './CustomTitle';

const CustomShow = props => {
    return (
        <Show {...props} actions={<ShowActions />} title={<CustomTitle />}>
            <SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="name">
                            <TextField source="name" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Labeled source="message">
                            <TextField source="message" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <Labeled source="confirmed">
                            <BooleanField source="confirmed" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <Labeled source="quantity">
                            <NumberField source="quantity" />
                        </Labeled>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Labeled source="otherInfos">
                            <RichTextField source="otherInfos" />
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;