import { Utils } from 'admin/core';

export const transformData = async data => {
    if (data) {
        if (data.profilePhotoUrl && Utils.IsObject(data.profilePhotoUrl)) {
            if (data.profilePhotoUrl.rawFile instanceof File) {
                let res = await Utils.ConvertFileToBase64({ contentData: { ...data.profilePhotoNavigation } });
                data.profilePhotoUrl = res.src;
            }
            else if (Utils.IsObject(data.profilePhotoUrl)) {
                data.profilePhotoUrl = data.profilePhotoUrl.src;
            }
        }
    }

    return { ...data }
};