import React, { useEffect } from 'react';

import {
    useTranslate
} from 'react-admin';

import {
    Box,
    Divider,
    Grid,
    Stack,
    Typography
} from '@mui/material';

import trekkingShoesImg from '../img/trekking_shoes.png';
import piuminoImg from '../img/piumino.png';
import pantsImg from '../img/pants.png';
import sunglassesImg from '../img/sunglasses.png';

interface Props {
}

export type DressCodeProps = Props;

const MyImg = (props: any) => {
    const {
        img,
    } = props;

    return <Box
        component="img"
        src={img}
        sx={{
            margin: 0,
            width: '14vh',
        }}
    />
}

const DressCode = (props: DressCodeProps) => {
    const {
        ...rest
    } = props;

    const translate = useTranslate();

    useEffect(() => {
    }, []);

    return (
        <Stack spacing={2} justifyContent="start" alignItems="center" sx={{ height: '95%', paddingTop: { xs: '20%', sm: 0 }, paddingX: 8 }}>
            <Typography variant='body2' sx={{ textDecoration: 'underline' }} fontSize={{ xs: '3vh', sm: '5vh' }}>
                {translate('menu.dressCode')}
            </Typography>
            <Typography variant='body2' fontSize={{ xs: '3vh', sm: '5vh' }}>
                {translate('dressCode.title')}
            </Typography>
            <Grid container spacing={2}>
                <Grid item sm={3} display={{ xs: 'none', sm: 'flex' }}>
                </Grid>
                <Grid item xs={5} sm={3} display='flex' justifyContent="center" alignItems="center">
                    <MyImg img={trekkingShoesImg} />
                </Grid>
                <Grid item xs={7} sm={3} display='flex' justifyContent="center" alignItems="center">
                    <Typography variant='body2' fontSize={{ xs: '2vh', sm: '3vh' }}>
                        {translate('dressCode.trekkings')}
                    </Typography>
                </Grid>
                <Grid item sm={3} display={{ xs: 'none', sm: 'flex' }}>
                </Grid>

                <Grid item sm={3} display={{ xs: 'none', sm: 'flex' }}>
                </Grid>
                <Grid item xs={7} sm={3} display='flex' justifyContent="center" alignItems="center">
                    <Typography variant='body2' fontSize={{ xs: '2vh', sm: '3vh' }}>
                        {translate('dressCode.giaccaVento')}
                    </Typography>
                </Grid>
                <Grid item xs={5} sm={3} display='flex' justifyContent="center" alignItems="center">
                    <MyImg img={piuminoImg} />
                </Grid>
                <Grid item sm={3} display={{ xs: 'none', sm: 'flex' }}>
                </Grid>

                <Grid item sm={3} display={{ xs: 'none', sm: 'flex' }}>
                </Grid>
                <Grid item xs={7} sm={3} display='flex' justifyContent="center" alignItems="center">
                    <MyImg img={pantsImg} />
                </Grid>
                <Grid item xs={5} sm={3} display='flex' justifyContent="center" alignItems="center">
                    <Typography variant='body2' fontSize={{ xs: '2vh', sm: '3vh' }}>
                        {translate('dressCode.pants')}
                    </Typography>
                </Grid>
                <Grid item sm={3} display={{ xs: 'none', sm: 'flex' }}>
                </Grid>

                <Grid item sm={3} display={{ xs: 'none', sm: 'flex' }}>
                </Grid>
                <Grid item xs={7} sm={3} display='flex' justifyContent="center" alignItems="center">
                    <Typography variant='body2' fontSize={{ xs: '2vh', sm: '3vh' }}>
                        {translate('dressCode.sunglasses')}
                    </Typography>
                </Grid>
                <Grid item xs={5} sm={3} display='flex' justifyContent="center" alignItems="center">
                    <MyImg img={sunglassesImg} />
                </Grid>
                <Grid item sm={3} display={{ xs: 'none', sm: 'flex' }}>
                </Grid>
            </Grid>
        </Stack>
    );
}

export default DressCode;