import React, { useEffect } from 'react';

import {
    Box,
    Stack,
    Typography,
} from '@mui/material';

import celeAleImg from '../img/cele_ale.png';
import ringsImg from '../img/rings.png';
import { useTranslate } from 'react-admin';

interface Props {
}

export type HomeProps = Props;

const Home = (props: HomeProps) => {
    const {
        ...rest
    } = props;
    const translate = useTranslate();

    useEffect(() => {
    }, []);

    return (
        <Stack spacing={2} justifyContent="space-evenly" alignItems="center" sx={{ height: '100%' }}>
            <Stack spacing={2} justifyContent="space-evenly" alignItems="center" sx={{ height: '30vh' }}>
                <Typography fontSize={{ xs: '4vh', sm: '5vh' }}>
                    {translate('home.wedding')}
                </Typography>
                <Typography fontSize={{ xs: '8vh', sm: '10vh' }}>Cele & Ale</Typography>
                <Box
                    component="img"
                    src={ringsImg}
                    sx={{
                        margin: 0,
                        height: { xs: '8vh', sm: '10vh' },
                    }}
                />
            </Stack>
            <Box
                component="img"
                src={celeAleImg}
                sx={{
                    margin: 0,
                    width: '40vh',
                }}
            />
        </Stack>
    );
}

export default Home;