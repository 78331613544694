import * as React from 'react';
import { Fragment } from 'react';

import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

import ExpandMore from '@mui/icons-material/ExpandMore';

import { useTranslate } from 'react-admin';

export interface SubMenuProps {
    handleToggle?: () => void;
    sidebarIsOpen: boolean;
    isOpen: boolean;
    name: string;
    icon?: React.ReactElement;
    children?: any;
    dense?: boolean;
};

const SubMenu = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    children,
    dense,
}: SubMenuProps) => {
    const translate = useTranslate();
    var name = translate(name);

    const header = (
        <Box
            onClick={handleToggle}
            sx={{
                "&:hover": {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                },
                display: 'inline-flex',
                width: '100%',
                justifyContent: 'start',
                alignItems: 'center',
                paddingTop: '6px',
                paddingBottom: '6px',
                boxSizing: 'border-box',
                whiteSpace: 'nowrap',
                paddingLeft: '16px',
                paddingRight: '16px',
                cursor: 'pointer'
            }}>
            <ListItemIcon
                sx={{
                    minWidth: theme => `${theme.spacing(5)} !important`,
                }}>
                {isOpen ? <ExpandMore /> : icon}
            </ListItemIcon>
            <Typography variant="inherit" component="span" color="textSecondary">
                {name}
            </Typography>
        </Box>
    );

    return (
        <Fragment>
            {header}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    sx={{
                        '& a': {
                            paddingLeft: theme => sidebarIsOpen ? theme.spacing(4) : theme.spacing(2),
                            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                        },
                        minWidth: theme => `${theme.spacing(5)} !important`,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'start',
                    }}
                    dense={dense}
                    component="div"
                    disablePadding
                >
                    {children}
                </List>
            </Collapse>
        </Fragment>
    );
};

export default SubMenu;
