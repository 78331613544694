import React, { cloneElement, isValidElement, memo } from 'react';
import PropTypes from 'prop-types';

import {
    SaveButton,
    Toolbar,
    useNotify,
    useCreateContext
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import { useFormContext } from 'react-hook-form';

import ComposedIcon from './ComposedIcon';

import Save from '@mui/icons-material/Save';
import Add from '@mui/icons-material/Add';

const CreateToolbar = memo((props) => {
    const { hasSaveAndAdd, customActions, ...rest } = props;
    
    const { reset } = useFormContext();
    const notify = useNotify();
    const createProps = useCreateContext(props);

    return (
        <Toolbar {...rest} style={{ justifyContent: 'end' }}>
            <SaveButton
                mutationOptions={{
                    onSuccess: () => {
                        notify('ra.notification.created', {
                            type: 'info',
                            messageArgs: { smart_count: 1 }
                        });
                    }
                }}
                disabled={createProps.isLoading}
            />
            {hasSaveAndAdd && <SaveButton
                icon={<ComposedIcon position="top-end" icon={<Save />} extraIcon={<Add />} />}
                label="ra.action.save_and_add"
                mutationOptions={{
                    onSuccess: () => {
                        notify('ra.notification.created', {
                            type: 'info',
                            messageArgs: { smart_count: 1 }
                        });
                        reset();
                    }
                }}
                type="button"
                variant="text"
                disabled={createProps.isLoading}
            />}
            {customActions.map((customAction, key) => isValidElement(customAction) && cloneElement(customAction, {
                ...createProps,
                key: key,
                disabled: createProps.isLoading,
                context: 'button',
            }))}
        </Toolbar>
    )
});

CreateToolbar.propTypes = {
    hasSaveAndAdd: PropTypes.bool,
    customActions: PropTypes.arrayOf(PropTypes.element)
};

CreateToolbar.defaultProps = {
    hasSaveAndAdd: true,
    customActions: [],
};

CreateToolbar.displayName = 'CreateToolbar';

export default CreateToolbar;