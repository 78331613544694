import * as React from 'react';
import { useMediaQuery } from '@mui/material';

import GoogleIcon from '@mui/icons-material/Google';
import LocalIcon from '@mui/icons-material/Email';
import UnknownIcon from '@mui/icons-material/DeviceUnknown';

import {
    List,
    SimpleList,
    Datagrid,
    EditButton,
    DeleteWithConfirmButton,
    ShowButton,
    TextField,
    DateField,
    BooleanField,
    EmailField,
    ImageField,
    FunctionField,
    Filter,
    SearchInput,
} from 'react-admin';

import { ListActions, ListBulkActions, ListRowActions } from 'admin/components';

const ListFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        {/* <BooleanInput source="xxx" />
        <ReferenceInput source="xxxId" reference="xxx" label="resources.xxx.fields.xxx">
            <SelectInput source="xxx" />
        </ReferenceInput>
        <NumberInput source="xxx" /> */}
    </Filter>
);

const CustomList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            actions={<ListActions />}
            filters={<ListFilter />}
            filterDefaultValues={{}}
            sort={{ field: 'firstName', order: 'ASC' }}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.displayName} (${record.email})`}
                    secondaryText={record => `${record.userName}`}
                    tertiaryText={record => record.roles}
                    linkType="edit"
                />
            ) : (
                <Datagrid bulkActionButtons={<ListBulkActions />} optimized sx={{ '& .column-profilePhotoUrl': { padding: ' 0 !important', textAlign: 'center' } }}>
                    {/* <TextField source="id" /> */}
                    {/* <ReferenceField source="identityId" reference="Identities" /> */}
                    <TextField source="firstName" />
                    <TextField source="lastName" />
                    <TextField source="userName" />
                    <EmailField source="email" />
                    <DateField source="phoneNumber" />
                    {/* <ReferenceField source="providerId" reference="Providers" /> */}
                    <FunctionField label="resources.users.fields.providerType" textAlign="center" render={record => {
                        switch (record.providerType) {
                            case "Google":
                                return <GoogleIcon />
                            case "Local":
                                return <LocalIcon />
                            default:
                                return <UnknownIcon />
                        }
                    }} />
                    <ImageField source="profilePhotoUrl" sx={{ '& img': { width: 'auto !important', height: '30px !important', margin: ' 0 !important' } }} />
                    <BooleanField source="changePassword" textAlign="center" />
                    {/* <TextField source="defaultCulture" /> */}
                    {/* <TextField source="status" /> */}
                    {/* <DateField source="createdAt" /> */}
                    <ListRowActions>
                        <EditButton />
                        <DeleteWithConfirmButton />
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
