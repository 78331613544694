import * as React from 'react';

import {
    Button,
    Typography,
    Card,
    CardContent,
    CardActions,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Loading, useGetList } from 'react-admin';

export const Welcome = () => {
    const { data, isLoading } = useGetList('guests', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
    });

    if (isLoading) return <Loading />;

    const total = data?.map(x => x.quantity).reduce((a, c) => a + c, 0);
    const totalSent = data?.filter(x => x.sent).map(x => x.quantity).reduce((a, c) => a + c, 0);
    const totalNotSent = data?.filter(x => !x.sent).map(x => x.quantity).reduce((a, c) => a + c, 0);
    const invited = data?.filter(x => x.confirmed).map(x => x.quantity).reduce((a, c) => a + c, 0);
    const confirmedFinal = data?.filter(x => x.confirmed).map(x => x.confirmedQuantity).reduce((a, c) => a + c, 0);
    const notConfirmed = data?.filter(x => !x.confirmed).map(x => x.quantity).reduce((a, c) => a + c, 0);

    return (
        <Card
            sx={{
                background: '#d9d9d9',
                padding: '20px',
                marginTop: 2,
                marginBottom: '1em',
            }}
        >
            <CardContent>
                <Typography variant="h5" sx={{ textDecoration: 'underline' }} gutterBottom>
                    Recap
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Total: {total}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Total Sent: {totalSent}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Total Not Sent: {totalNotSent}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Confirmed: {confirmedFinal} (+ {invited - confirmedFinal} non confermato)
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Not Confirmed: {notConfirmed}
                </Typography>
            </CardContent>
        </Card>
    )
};