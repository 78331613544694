import React, { useEffect, useState } from 'react';

import SaveIcon from '@mui/icons-material/Save';

import {
    useNotify,
    useTranslate
} from 'react-admin';

import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from '@mui/material';

import { Guest } from 'publicapp/types';
import { dataProvider } from 'publicapp/providers';

interface Props {
    guest?: Guest;
    setGuest?: (data: Guest) => void
}

export type HomeProps = Props;

const Rsvp = (props: HomeProps) => {
    const {
        guest = {
            id: '',
            confirmed: null,
            name: '',
            quantity: 1,
            confirmedQuantity: 0,
            message: '',
            otherInfos: ''
        } as Guest,
        setGuest = () => { },
        ...rest
    } = props;

    const [quantity, setQuantity] = useState<number>(guest.confirmedQuantity > 0 ? guest.confirmedQuantity : guest.quantity);
    const [confirmed, setConfirmed] = useState<string>(guest.confirmedQuantity === 0 ? "" : (guest.confirmed ? 'confirmed' : 'notConfirmed'));
    const [otherInfos, setOtherInfos] = useState<string>(guest.otherInfos);
    const translate = useTranslate();
    const notify = useNotify();

    useEffect(() => {
    }, []);

    const onSave = () => {
        const isConfirmed = confirmed === 'confirmed';

        if (guest.id && (isConfirmed !== guest.confirmed || otherInfos !== guest.otherInfos)) {
            const data = {
                id: guest.id,
                confirmedQuantity: quantity,
                confirmed: confirmed === 'confirmed',
                otherInfos: otherInfos
            };

            dataProvider.update('guests/update', { id: guest.id, data: data, previousData: guest })
                .then(({ data }) => {
                    setGuest(data);
                    setTimeout(() => {
                        notify("rsvp.updated", { type: 'success' })
                    }, 500);
                })
                .catch(error => {
                    console.error(error);
                    notify("rsvp.error", { type: 'error' })
                });
        }
    }

    const onConfirmChange = (event: React.ChangeEvent, value: string) => {
        if (value === "notConfirmed")
            setQuantity(guest.quantity);

        setConfirmed(value);
    }

    const onQuantityChange = (event: SelectChangeEvent) => {
        setQuantity(parseInt(event.target.value))
    }

    const onOtherInfoTyping = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setOtherInfos(event.target.value);
    };

    const getItems = () => {
        return [...Array(guest.quantity).keys()].map((element, key) => {
            const value = element + 1;
            return <MenuItem key={key} value={value.toString()}>{value}</MenuItem>
        });
    }

    return (
        <Stack spacing={2} justifyContent="start" alignItems="center" sx={{ height: '95%', paddingTop: { xs: '10%', sm: 0 }, paddingX: 8 }}>
            <Typography fontSize={{ xs: '3vh', sm: '4vh' }}>
                {translate('rsvp.greetings')}
            </Typography>
            <Typography
                component='span'
                dangerouslySetInnerHTML={{ __html: guest.message }}
                sx={{ width: '100%', overflowWrap: 'break-word', lineHeight: '1em' }}
                fontSize={guest.quantity > 1 ? '6vh' : '10vh'}
                textAlign='center'
                fontWeight='bold'
            />
            <Typography fontSize={{ xs: '3vh', sm: '4vh' }} textAlign='center'>
                {translate('rsvp.infoMessage', { smart_count: guest.quantity })}
            </Typography>
            <Box display='flex' justifyContent="start" alignItems="center" sx={{ pt: 3 }}>
                <Stack spacing={2} justifyContent="start" alignItems="center">
                    <Typography fontSize={{ xs: '4vh', sm: '5vh' }} textAlign='center'>
                        {translate('rsvp.confirmMessage', { smart_count: guest.quantity })}
                    </Typography>
                    <FormControl>
                        <RadioGroup value={confirmed} onChange={onConfirmChange}>
                            <FormControlLabel
                                value="confirmed"
                                control={<Radio />}
                                label={
                                    <Typography component='div' fontSize={{ xs: '2vh', sm: '4vh' }}>
                                        {translate('rsvp.confirm', { smart_count: guest.quantity })}
                                        {
                                            guest.quantity > 1 &&
                                            <Select variant="standard" value={quantity.toString()} sx={{ marginLeft: 2, fontSize: { xs: '3vh', sm: '4vh' } }} onChange={onQuantityChange}>
                                                {getItems()}
                                            </Select>
                                        }
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                value="notConfirmed"
                                control={<Radio />}
                                label={
                                    <Typography fontSize={{ xs: '2vh', sm: '4vh' }}>
                                        {translate('rsvp.notConfirm', { smart_count: guest.quantity })}
                                    </Typography>}
                            />
                        </RadioGroup>
                        <TextField
                            disabled={confirmed !== 'confirmed'}
                            variant="standard"
                            value={otherInfos}
                            label={translate('rsvp.otherInfos')}
                            multiline
                            maxRows={4}
                            inputProps={{ maxLength: 200, sx: { fontSize: { xs: '2vh', sm: '3vh' } } }}
                            onChange={onOtherInfoTyping}
                        />
                        <Button variant="contained" endIcon={<SaveIcon />} sx={{ color: '#fff', mt: 3 }} onClick={onSave}>
                            {translate('rsvp.save')}
                        </Button>
                    </FormControl>
                </Stack>
            </Box>
        </Stack>
    );
}

export default Rsvp;