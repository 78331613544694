import { MouseEventHandler, ReactElement, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Icon from '@mui/icons-material/ContentCopy';
import {
    Button,
    ButtonProps,
    useNotify,
    useRecordContext
} from 'react-admin';
import { green, red } from '@mui/material/colors';

interface Props {
    className?: string;
    disabled?: boolean;
    icon?: ReactElement;
    invalid?: boolean;
    label?: string;
    variant?: string;
}

export type CopyButtonProps = Props &
    ButtonProps & {
        alwaysEnable?: boolean;
    };

export const CopyButton = (
    props: CopyButtonProps
) => {
    const record = useRecordContext();
    const notify = useNotify();
    const [copied, setCopied] = useState(false);

    const {
        icon = defaultIcon,
        label = '',
        onClick,
        className,
        ...rest
    } = props;


    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        async event => {
            event.stopPropagation();
            navigator.clipboard.writeText(`${window.location.origin}/${record.id}`);
            notify("Link Copied", { type: 'success' });
            setCopied(true);
        },
        [onClick]
    );

    return (
        <StyledButton
            label={label}
            onClick={handleClick}
            className={clsx(BackButtonClasses.root, className)}
            {...(rest as any)}
            sx={{ color: copied ? green[700] : red[600] }}
        >
            {icon}
        </StyledButton>
    );
};

const defaultIcon = <Icon />;

CopyButton.propTypes = {
    className: PropTypes.string,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    icon: PropTypes.element,
};

const PREFIX = 'CopyButton';

export const BackButtonClasses = {
    root: `${PREFIX}-root`,
};

const StyledButton = styled(Button, {
    name: PREFIX,
    overridesResolver: (_props, styles) => styles.root,
})({});