import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from "@mui/styles";
import { CircularProgress, Fab, Typography, } from '@mui/material';
import ExtensionIcon from '@mui/icons-material/Extension';
import { green } from '@mui/material/colors';

import {
    useTranslate,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

const useStyles = makeStyles(theme => (
    {
        message: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0.8,
            margin: '0 1em',
            color: theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
        },
        wrapper: {
            width: theme.spacing(7),
            margin: theme.spacing(4),
            position: 'relative',
        },
        fabProgress: {
            color: green[600],
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
        },
    }
));

const LoadingPage = (props) => {
    const { 
        label,
        icon,
        iconColor,
        textVariant,
        textAsParagraph
    } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.message}>
            <div className={classes.wrapper}>
                <Fab aria-label={translate(label)} color={iconColor}>
                    {icon}
                </Fab>
                <CircularProgress size={68} className={classes.fabProgress} />
            </div>
            <Typography variant={textVariant} paragraph={textAsParagraph}>
                {translate(label)}
            </Typography>
        </div>
    );
};

LoadingPage.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.element,
    iconColor: PropTypes.string,
    textVariant: PropTypes.string,
    textAsParagraph: PropTypes.bool
};

LoadingPage.defaultProps = {
    label: "ra.page.loading",
    icon: <ExtensionIcon />,
    iconColor: "primary",
    textVariant: "h4",
    textAsParagraph: true
};

LoadingPage.displayName = 'LoadingPage';

export default LoadingPage;