import { EditGuesser, ShowGuesser } from 'react-admin';

import Icon from '@mui/icons-material/PermMedia';
import CustomEdit from './CustomEdit';
import CustomList from './CustomList';
import CustomShow from './CustomShow';

export default {
    list: CustomList,
    edit: EditGuesser,
    show: ShowGuesser,
    icon: Icon,
};