import React, { forwardRef, Children } from 'react';

import {
    AppBar,
    AppBarProps,
    Logout,
    UserMenu,
    useBasename,
    useTranslate,
    useUserMenu,
    usePermissions,
} from 'react-admin';

import { MenuItem, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { UserProfile } from './UserProfile';

const useStyles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textAlign: 'center'
    },
    menuItem: {
        borderLeft: 'none',
    },
    spacer: {
        flex: 1,
    },
};

const ConfigurationMenu = forwardRef((props: any, ref) => {
    const translate = useTranslate();
    const basename = useBasename();
    const { onClose } = useUserMenu();
    return (
        <MenuItem
            ref={ref}
            sx={useStyles.menuItem}
            {...props}
            to={`${basename}/configuration`}
            primaryText={translate('pos.menu.configurations')}
            onClick={onClose}
        >
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText>Configuration</ListItemText>
        </MenuItem>
    );
});

// const ConfigurationMenu = forwardRef((props: any, ref) => {
//     const translate = useTranslate();
//     const basename = useBasename();
//     return (
//         <MenuItemLink
//             sx={useStyles.menuItem}
//             ref={ref}
//             to={`${basename}/configuration`}
//             primaryText={translate('pos.menu.configurations')}
//             leftIcon={<SettingsIcon />}
//             onClick={props.onClick}
//             sidebarIsOpen
//         />
//     );
// });

const LogoutButton = (props: any) => <Logout {...props} icon={<ExitToAppIcon />} />;

const CustomUserMenu = ({ permissions, ...props }: any) => (
    <UserMenu {...props}>
        <UserProfile />
        {/* {['admin', 'owner'].includes(permissions) && <ConfigurationMenu />} */}
        <LogoutButton />
    </UserMenu>
);

export type HeaderProps = AppBarProps & {
    logo?: string
    logoStyle?: any
};

const Header: React.FC<HeaderProps> = ({ logo, logoStyle, ...props }) => {
    const {
        children,
        className,
        color = 'secondary',
        userMenu,
        ...rest
    } = props;

    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu permissions={permissions} />}>
            {logo ? <img src={logo} alt="logo" style={logoStyle} /> : null}
            {Children.count(children) === 0 ? (
                <Typography
                    sx={useStyles.title}
                    variant="h6"
                    color="inherit"
                    id="react-admin-title"
                />
            ) : (
                children
            )}
            {/* <LocalesMenuButton languages={[
                { locale: 'it', name: translate('pos.languages.it') },
                { locale: 'en', name: translate('pos.languages.en') }
            ]} /> */}
        </AppBar>
    );
};

export default Header;