import { Utils } from '../../core';

import * as tables from './Tables';
import * as settings from "./Settings";
import * as userprofile from "./userprofile";

export default {

    resources: {
        ...Utils.BuildTranslations(tables, "resources"),
        ...Utils.BuildTranslations(settings, "resources"),
        ...Utils.BuildTranslations(userprofile, "resources"),
    },

    ...Utils.BuildTranslations(tables, "global"),
    ...Utils.BuildTranslations(settings, "global"),
    ...Utils.BuildTranslations(userprofile, "global"),

    pos: {
        language: 'Language',
        languages: {
            it: 'Italian',
            en: 'English',
        },
        configurations: {
            title: 'Configurations',
            updated: 'Configurations updated'
        },
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        auth: {
            login_failure: 'Autenticazione fallita, riprovare.',
            not_allowed: 'Permessi insufficienti per accedere',
            reset_password_required: 'Richiesto reset password. Usare il link ricevuto per e-mail.',
            password_not_resetted: 'Non è stato possibile resettare la password',
            mail_not_found: 'La mail non risulta inserita a sistema',
            reset_password_failure: 'Reset della password fallito, riprovare',
        },
        menu: {
            tables: "Tabelle",
            profile: "Profile",
            settings: "Settings",
            configurations: "Configurations"
        },
        media_manager: {
            title: 'Media Gallery',
            tab1: 'Select',
            tab2: 'Upload',
            title_field: 'Select file',
            saved: "Saved media",
            saved_many: "Saved medias",
            not_saved: 'Media not saved in the Db. Base64 will be used'
        },
        dashboard: {},
        fileupload: {
            errors: {
                ["file-invalid-type"]: "File type must be one of: %{type}",
                ["file-too-large"]: "File is larger than %{size}",
            }
        }
    },
}