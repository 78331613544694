import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { useTheme, useMediaQuery } from '@mui/material';
import { Box, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/RemoveCircle';

import { Confirm, useListContext, useDelete } from 'react-admin';
import { Link } from 'react-router-dom';

import { Utils } from 'admin/core';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: 'white',
    },
}));

const GridList = () => {
    const { isLoading } = useListContext();
    return isLoading ? <LoadingGridList /> : <LoadedGridList />;
};

const useColsForWidth = () => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));
    // there are all dividers of 24, to have full rows on each page
    if (xl) return 8;
    if (lg) return 6;
    if (md) return 4;
    if (sm) return 3;
    return 2;
};

const times = (nbChildren, fn) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = () => {
    const { perPage } = useListContext();
    const cols = useColsForWidth();
    return (
        <ImageList rowHeight={180} cols={cols} sx={{ m: 0 }}>
            {times(perPage, key => (
                <ImageListItem key={key}>
                    <Box bgcolor="grey.300" height="100%" />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

const LoadedGridList = () => {
    const { data } = useListContext();
    const cols = useColsForWidth();

    if (!data) return null;

    return (
        <ImageList rowHeight={180} cols={cols} sx={{ m: 0 }}>
            {data.map(record => (
                <ImageListItem
                    component={Link}
                    key={record.id}
                >
                    <img src={record.src} alt="" style={{ maxHeight: '100%', objectFit: 'contain' }} />
                    <ImageListItemBar
                        title={record.title}
                        subtitle={
                            <span>
                                {Utils.FormatBytes(record.size)}
                            </span>
                        }
                        sx={{
                            background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
                        }}
                        actionIcon={record.mediaTypeId !== 'DefaultProfilePicture' && <ConfirmDeleteButton record={record} />}
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

const ConfirmDeleteButton = ({ record }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [deleteOne, { isLoading, error }] = useDelete();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = (record) => {
        deleteOne('medias', { id: record.id, previousData: record });
        setOpen(false);
    };

    return (
        <>
            <IconButton aria-label={'remove'} className={classes.icon} onClick={handleClick}>
                <RemoveIcon />
            </IconButton>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`Delete ${record.title}`}
                content="Are you sure you want to delete this item?"
                onConfirm={() => handleConfirm(record)}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default GridList;