import React, { ReactNode, isValidElement, useState, useEffect, cloneElement } from 'react';
import PropTypes from 'prop-types';

import {
    useLoading,
    useTranslate
} from 'react-admin';

import { Utils } from 'admin/core';

import { Dialog as MUIDialog, DialogProps as MUIDialogProps, Button, IconButton, Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';

import { withStyles } from "@mui/styles";

interface Props {
    title?: string | ReactNode;
    actions?: ReactNode[] | null;
    onOpen?: () => void;
    onClosed?: () => void;
    children?: ReactNode | null;
    minWidth?: string | number | undefined;
    hideClose?: boolean;
    preventEscapeKeyDown?: boolean;
    preventBackdropClick?: boolean;
}

export type DialogProps = Props & MUIDialogProps;

const DialogTitle = (props) => {
    const { children, onClose, hideClose, ...other } = props;
    return (
        <MuiDialogTitle
            sx={{
                margin: 0,
                padding: theme => theme.spacing(2),
                backgroundColor: theme => theme.palette.secondary.main,
                color: theme => theme.palette.getContrastText(theme.palette.secondary.main),
            }}
            {...other}
        >
            {children}
            {!hideClose ? (
                <IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: theme => theme.spacing(1),
                        top: theme => theme.spacing(1),
                        color: theme => theme.palette.getContrastText(theme.palette.secondary.main),
                    }}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
}

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions)

const Dialog = (props: DialogProps) => {
    const {
        title = '',
        actions,
        open = false,
        onOpen = () => { },
        onClosed = () => { },
        children,
        minWidth = 300,
        hideClose = false,
        preventEscapeKeyDown = false,
        preventBackdropClick = false,
        ...rest
    } = props;

    const translate = useTranslate();
    const [innerOpen, setInnerOpen] = useState(open);
    const [isFirstOpen, setIsFirstOpen] = useState(false);
    const loading = useLoading();

    useEffect(() => {
        if (isFirstOpen && !loading) {
            setIsFirstOpen(false)
        }
    }, [loading]);

    useEffect(() => {
        if (open && !innerOpen) {
            setInnerOpen(true);
            onOpen();
        }
    }, [open]);

    const onClose = (event: object, reason: string) => {
        if (!(reason === 'escapeKeyDown' && preventEscapeKeyDown || reason === 'backdropClick' && preventBackdropClick)) {
            setInnerOpen(false);
            onClosed();
        }
    };

    let finalActions = actions ? actions.filter(x => x !== null && isValidElement(x)) : [];

    return (
        <>
            <MUIDialog
                open={open}
                onClose={onClose}
                maxWidth={"lg"}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: theme => theme.palette.background.default,
                        minWidth: minWidth,
                        maxWidth: '100%',
                    }
                }}
                {...rest}
            >
                {
                    title && (
                        <DialogTitle onClose={onClose} hideClose={hideClose}>
                            {Utils.IsString(title) ? translate(title) : title}
                        </DialogTitle>
                    )
                }
                <DialogContent dividers>
                    {children}
                </DialogContent>
                {
                    finalActions.length > 0 && (
                        <DialogActions>
                            {
                                finalActions.map((action, key) => isValidElement(action) && cloneElement(action, { key }))
                            }
                        </DialogActions>
                    )
                }
            </MUIDialog>
        </>
    );
}

export default Dialog;