import { ReactElement, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Icon from '@mui/icons-material/Check';

import {
    Button,
    Confirm,
    useRecordContext,
    ButtonProps,
} from 'react-admin';

interface Props {
    className?: string;
    disabled?: boolean;
    icon?: ReactElement;
    onlyButton?: boolean;
    label?: string;
    variant?: string;
    title?: React.ReactNode;
    content?: React.ReactNode;
    translateOptions?: object;
    onConfirm: (data: any) => void;
}

export type ConfirmButtonProps = Props & ButtonProps;

const ConfirmButton = (props: ConfirmButtonProps) => {
    const {
        icon = defaultIcon,
        label = 'ra.action.back',
        onClick,
        onConfirm,
        title = 'ra.action.confirm',
        content = '',
        translateOptions,
        className,
        onlyButton,
        ...rest
    } = props;

    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        setIsLoading(true);
        onConfirm(record);
        setOpen(false);
        setIsLoading(false);
    };

    if (onlyButton) {
        return (
            <StyledButton
                label={label}
                className={clsx(ConfirmButtonClasses.root, className)}
                {...(rest as any)}
            >
                {icon}
            </StyledButton>
        );
    }
    else {
        return (
            <>
                <StyledButton
                    label={label}
                    onClick={handleClick}
                    className={clsx(ConfirmButtonClasses.root, className)}
                    {...(rest as any)}
                >
                    {icon}
                </StyledButton>
                <Confirm
                    isOpen={open}
                    loading={isLoading}
                    title={title}
                    content={content}
                    translateOptions={translateOptions}
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
            </>
        );
    }
};

const defaultIcon = <Icon />;

ConfirmButton.propTypes = {
    className: PropTypes.string,
    onlyButton: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    icon: PropTypes.element,
};

const PREFIX = 'RaConfirmButton';

export const ConfirmButtonClasses = {
    root: `${PREFIX}-root`,
};

const StyledButton = styled(Button, {
    name: PREFIX,
    overridesResolver: (_props, styles) => styles.root,
})({});

export default ConfirmButton