import React from 'react';
import classnames from 'classnames';

import {
    useTranslate,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { makeStyles } from "@mui/styles";
import FileUploadIcon from '@mui/icons-material/Publish';
import FileUploadedIcon from '@mui/icons-material/Check';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
    placeholderIcon: {
        fontSize: '2.5em',
        position: 'absolute',
        color: '#aeaeae',
        [theme.breakpoints.up('md')]: { left: '.25em', top: '.25em' },
        [theme.breakpoints.down('md')]: { left: '.25em', top: '.25em' },
        '&.uploaded': {
            color: '#33cc01',
        }
    },
}));

const FileUploadPlaceholder = ({ hasFiles, multiple, hideOnFileLoaded, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();

    if (hasFiles && hideOnFileLoaded)
        return null;

    let label = multiple ? translate("ra.input.file.upload_several") : translate("ra.input.file.upload_single");
    let icon = <FileUploadIcon className={classes.placeholderIcon} />;

    if (hasFiles) {
        label = multiple ? translate("ra.input.file.upload_several") : translate("ra.input.file.uploaded_single");
        icon = <FileUploadedIcon className={classnames(classes.placeholderIcon, 'uploaded')} />;
    }

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} sx={{ position: 'relative' }}>
                {icon}
                <Typography variant="body1" display="inline">
                    {label}
                </Typography>
            </Grid>
        </Grid>
    )
}

FileUploadPlaceholder.defaultProps = {
    files: false,
    multiple: false,
    hideOnFileLoaded: false,
};

FileUploadPlaceholder.displayName = 'FileUploadPlaceholder';

export default FileUploadPlaceholder;