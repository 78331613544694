export default {
    name: "guests",
    resources: {
        name: 'Ospite |||| Ospiti',
        forcedCaseName: 'Ospite |||| Ospiti',
        fields: {
            name: "Name",
            message: "Messaggio",
            confirmed: "Confermato",
            otherInfos: "Altre info",
            quantity: "Quantità"
        },
    },
    global: {
        menu: {
            name: 'Ospiti',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Ospite "%{title}"',
        },
        action: {
            sent: 'Inviato',
            not_sent: 'Non Inviato',
        },
    },
};