const englishMessages = {
    ra: {
        action: {
            add_filter: 'Add filter',
            add: 'Add',
            add_custom: 'Add %{name}',
            back: 'Go Back',
            bulk_actions: '1 item selected |||| %{smart_count} items selected',
            cancel: 'Cancel',
            clear_filter: 'Clear filter',
            clear_input_value: 'Clear value',
            clone: 'Clone',
            confirm: 'Confirm',
            create: 'Create',
            delete: 'Delete',
            edit: 'Edit',
            export: 'Export',
            import: 'Import',
            download: 'Download file',
            upload: 'Upload file',
            list: 'List',
            refresh: 'Refresh',
            remove_filter: 'Remove this filter',
            remove: 'Remove',
            remove_custom: 'Remove %{name}',
            save: 'Save',
            save_and_edit: "Save and edit",
            save_and_show: "Save and show",
            save_and_add: "Save and add",
            bulkEnabled: 'Enabled all',
            search: 'Search',
            show: 'Show',
            sort: 'Sort',
            undo: 'Undo',
            unselect: 'Unselect',
            expand: 'Expand',
            close: 'Close',
            close_all: 'Close All',
            open_menu: 'Open menu',
            close_menu: 'Close menu',
            scroll_to_top: 'Scroll to top',
            mode: {
                sortableList: 'Sort List',
            },
            showHtmlPreview: 'Show HTML',
            getFile: 'Download',
            sync: 'Sync',
            manage: 'Manage',
            restore: 'Restore',
            open: 'Open',
            load_more: 'Load more',
        },
        boolean: {
            true: 'Yes',
            false: 'No',
            null: '',
        },
        page: {
            create: 'Create %{name}',
            dashboard: 'Dashboard',
            edit: '%{name}',
            error: 'Something went wrong',
            list: '%{name}',
            loading: 'Loading',
            not_found: 'Not Found',
            show: '%{name}',
            empty: 'No %{name} yet.',
            invite: 'Do you want to add one?',
            invite_more: 'Do you want to add records?',
            initialize_table: 'Do you want to initialize the table?',
            unauthorized: 'Access to this page is not allowed',
        },
        input: {
            file: {
                not_found: 'Document not found or damaged',
                upload_several: 'Drop some files to upload, or click to select one',
                upload_single: 'Drop a file to upload, or click to select it',
                uploaded_single: 'Ok! Document uploaded',
                uploaded_several: 'Ok! Documents uploaded',
            },
            image: {
                upload_several:
                    'Drop some pictures to upload, or click to select one.',
                upload_single:
                    'Drop a picture to upload, or click to select it.',
            },
            references: {
                all_missing: 'Unable to find references data.',
                many_missing:
                    'At least one of the associated references no longer appears to be available.',
                single_missing:
                    'Associated reference no longer appears to be available.',
            },
            password: {
                toggle_visible: 'Hide password',
                toggle_hidden: 'Show password',
            },
            sortable: {
                all_selected: 'All items selected',
                selected: '%{count} selected',
                choices: 'Choices',
                chosen: 'Chosen',
            },
            copy: 'Copy text',
            copied: 'Text copied',
            copy_custom: 'Copy \'%{name}\' value',
            copied_custom: '\'%{name}\' value copied',
        },
        message: {
            about: 'About',
            are_you_sure: 'Are you sure?',
            bulk_delete_content: 'Are you sure you want to delete this item? |||| Are you sure you want to delete these %{smart_count} items?',
            bulk_delete_title: 'Delete selected item |||| Delete %{smart_count} elements',
            delete_content: 'Are you sure you want to delete this item?',
            delete_title: 'Delete selected item',
            details: 'Details',
            error: "A client error occurred and your request couldn't be completed.",
            empty: "No data available",
            invalid_form: 'The form is not valid. Please check for errors',
            loading: 'The page is loading, just a moment please',
            no: 'No',
            not_found: 'Either you typed a wrong URL, or you followed a bad link.',
            yes: 'Yes',
            unsaved_changes: "Some of your changes weren't saved. Are you sure you want to ignore them?",
            unauthorized: 'Insufficient permissions',
            or: 'Or',
        },
        navigation: {
            no_results: 'No results found',
            no_more_results:
                'The page number %{page} is out of boundaries. Try the previous page.',
            page_out_of_boundaries: 'Page number %{page} out of boundaries',
            page_out_from_end: 'Cannot go after last page',
            page_out_from_begin: 'Cannot go before page 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
            page_rows_per_page: 'Rows per page:',
            next: 'Next',
            previous: 'Prev',
        },
        sort: {
            sort_by: 'Sort by %{field} %{order}',
            ASC: 'ascending',
            DESC: 'descending',
        },
        auth: {
            auth_check_error: 'Please login to continue',
            user_menu: 'Profile',
            username: 'Username',
            password: 'Password',
            sign_in: 'Sign in',
            reset_password: 'Send',
            sign_in_error: 'Authentication failed, please retry',
            reset_password_error: 'Password reset failed, please retry.',
            reset_password_success: 'Reset della password completato. Seguire le indicazioni ricevute via l\'email.',
            reset_password_title: 'Reset password',
            logout: 'Logout',
            forgot_password: 'Forgot password',
            back_to_homepage: 'Back to homepage',
            back_to_loginpage: 'Back to login',
            social_login_button: {
                google: 'Sign in with Google',
                microsoft: 'Sign in with Microsoft',
            }
        },
        notification: {
            updated: 'Element updated |||| %{smart_count} elements updated',
            updated_more: 'More elements updated',
            created: 'Element created',
            deleted: 'Element deleted |||| %{smart_count} elements deleted',
            bad_item: 'Incorrect element',
            item_doesnt_exist: 'Element does not exist',
            http_error: 'Server communication error',
            data_provider_error:
                'dataProvider error. Check the console for details.',
            i18n_error:
                'Cannot load the translations for the specified language',
            canceled: 'Action cancelled',
            logged_out: 'Your session has ended, please reconnect.',
            mode: {
                sortableListUpdated: 'New sorting list saved',
                sortableListNotUpdated: 'New sorting list not saved',
            },
            file: {
                not_found: 'Document not found or damaged',
            }
        },
        validation: {
            required: 'Required',
            minLength: 'Must be %{min} characters at least',
            maxLength: 'Must be %{max} characters or less',
            minValue: 'Must be at least %{min}',
            maxValue: 'Must be %{max} or less',
            number: 'Must be a number',
            email: 'Must be a valid email',
            emailNotUnique: 'Email is already taken',
            tel: 'Must be a valid telephone number',
            oneOf: 'Must be one of: %{options}',
            regex: 'Must match a specific format (regexp): %{pattern}',
            bool: 'Required',
            iban: 'Must be a valid IBAN code',
            dropZone: {
                'file-invalid-type': 'File type must be %{messageSuffix} |||| File type must be one of %{messageSuffix}',
                'file-too-large': 'File is larger than %{maxSize}',
                'file-too-small': 'File is smaller than %{minSize}',
                'too-many-files': 'Too many files',
            }
        },
    },
};

export default englishMessages;