import React, { useEffect, useState } from 'react';

import GoogleMap from 'google-map-react';

import {
    Box,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';

import Icon from '@mui/icons-material/Directions';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import ImageIcon from '@mui/icons-material/Image';

import routeImg from '../img/route.jpg';

import celeAleImg from '../img/cele_ale.png';
import { Link } from 'react-router-dom';
import { DialogButton } from 'admin/components';

interface Props {
}

export type HomeProps = Props;

const directionLink = "https://www.google.it/maps/place/45%C2%B001'29.4%22N+6%C2%B052'07.1%22E/@45.0248209,6.8679963,19z/data=!3m1!4b1!4m4!3m3!8m2!3d45.02482!4d6.86864?entry=ttu";
const cartelloLink = "https://www.google.it/maps/@45.0248333,6.8687631,3a,19.3y,182.88h,89.69t/data=!3m7!1e1!3m5!1sJjqfDGSGR0Aht822d1z-WA!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DJjqfDGSGR0Aht822d1z-WA%26cb_client%3Dmaps_sv.tactile.gps%26w%3D203%26h%3D100%26yaw%3D281.93094%26pitch%3D0%26thumbfov%3D100!7i13312!8i6656?coh=205409&entry=ttu";
const chaletIlCapricornoLink = "https://www.google.it/maps/place/Chalet+Il+Capricorno/@45.0200697,6.8687969,386m/data=!3m1!1e3!4m9!3m8!1s0x4789c15fa79e277b:0x24c320611567e14b!5m2!4m1!1i2!8m2!3d45.0195565!4d6.8697708!16s%2Fg%2F1tgprhv6!5m1!1e4?entry=ttu";

const MarkerComponent = ({ lat, lng, zoomLevel }) => {
    if (zoomLevel >= 16.5) {
        return <div>
            <img src={celeAleImg} style={{ margin: 0, height: '8vh' }} />
        </div>
    }
    return null;
};

function createMapOptions(maps) {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
        disableDefaultUI: true,
        zoomControl: true,
        mapTypeControl: true,
        mapTypeId: "satellite",
        mapTypeControlOptions: {
            //mapTypeIds: ["satellite", "roadmap"],
            style: maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: maps.ControlPosition.TOP_LEFT
        },
        fullscreenControlOptions: {
            position: maps.ControlPosition.TOP_RIGHT,
        },
        zoomControlOptions: {
            style: maps.ZoomControlStyle.SMALL
        },
    };
}

const Where = (props: HomeProps) => {
    const defaultProps = {
        center: {
            lat: 45.0187835,
            lng: 6.8763961
        },
        zoom: 18
    };

    const [zoomLevel, setZoomLevel] = useState(defaultProps.zoom);

    useEffect(() => {
    }, []);

    const onBoundsChange = (center, zoom /* , bounds, marginBounds */) => {
        setZoomLevel(zoom);
    }

    return (
        <Stack spacing={2} justifyContent="space-evenly" alignItems="center" sx={{ height: '95%', paddingTop: { xs: '0%', sm: 0 } }}>
            {/* <Typography variant='body2' sx={{ textDecoration: 'underline' }} fontSize={{ xs: '4vh', sm: '6vh' }}>
                Hotel Ciao Pais
            </Typography>
            <Typography variant='body1' fontSize={{ xs: '3vh', sm: '4vh' }}>
                Via case sparse, 17
            </Typography>
            <Typography variant='body1' fontSize={{ xs: '3vh', sm: '4vh' }}>
                10050 Sauze D'oulx TO
            </Typography> */}
            <Box sx={{ mt: "0!important", height: '5%!important' }}>
                <Link to={directionLink} target='_blank'>
                    <IconButton sx={{
                        color: theme => theme.palette.secondary.main,
                    }} >
                        <Icon sx={{ fontSize: '5vh' }} />
                    </IconButton >
                </Link>
            </Box>

            <Box sx={{ mt: "0!important", height: '40%!important', width: '65vw' }}>
                <GoogleMap
                    bootstrapURLKeys={{ key: "AIzaSyBQEA97yd-eUaoDcMlwLnkGOudW_6YULX0" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    onBoundsChange={onBoundsChange}
                    options={createMapOptions}
                >
                    <MarkerComponent
                        zoomLevel={zoomLevel}
                        lat={defaultProps.center.lat}
                        lng={defaultProps.center.lng}
                    />
                </GoogleMap>
            </Box>

            <Box sx={{ mt: "0!important", height: '40%!important', width: '65vw' }}>
                <Typography variant='body1' fontSize={{ xs: '3vh', sm: '4vh' }} textAlign={"center"}>
                    Arrivati all'incrocio, andate a DX su Via Clotes (seguite il &nbsp;
                    <Link to={cartelloLink} target='_blank'>
                        cartello
                    </Link>
                    ).<br />
                    Superato "Chalet Il Capricorno" (
                    <Link to={chaletIlCapricornoLink} target='_blank'>
                        <IconButton sx={{
                            px: 0,
                            color: theme => theme.palette.secondary.main,
                        }} >
                            <Icon />
                        </IconButton >
                    </Link>
                    ) &nbsp;andate a SX (
                    <Link to={routeImg} target='_blank'>
                        <IconButton sx={{
                            px: 0,
                            color: theme => theme.palette.secondary.main,
                        }} >
                            <ImageIcon />
                        </IconButton >
                    </Link>
                    ) e dopo qualche curva siete arrivati <SentimentSatisfiedAltIcon sx={{ fontSize: '3vh', color: theme => theme.palette.secondary.main }} />
                </Typography>
            </Box>
        </Stack>
    );
}

export default Where;