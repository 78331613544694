import React, { Children, ReactNode } from 'react';
import PropTypes from 'prop-types';

import {
    ListButton,
    CloneButton,
    EditButton,
    TopToolbar,
    useShowContext,
    usePermissions,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { Utils } from 'admin/core';

import ArrowBack from '@mui/icons-material/ArrowBack';
import { BackButton } from '../../buttons';

interface Props {
    children?: ReactNode[] | null;
    disabled?: boolean;
    hasList?: boolean;
    hasBack?: boolean;
    hasClone?: boolean;
    hasEdit?: boolean;
}

export type ShowActionsProps = Props;

const ShowActions = (props: ShowActionsProps) => {
    const {
        disabled = false,
        hasList = false,
        hasBack = true,
        hasClone = false,
        hasEdit = true,
        children = [],
    } = props;

    const { permissions } = usePermissions();
    const showProps = useShowContext();

    const { isLoading, record } = showProps;

    return (
        <TopToolbar>
            {hasList && !hasBack && <ListButton icon={<ArrowBack />} label={"ra.action.back"} disabled={isLoading} />}
            {hasBack && <BackButton disabled={isLoading} />}
            {hasClone && <CloneButton />}
            {hasEdit && <EditButton />}
            {Children.map(children, (child: any, index) => {
                const { disabled: disabledChild, ...childProps } = child.props

                const commonProps = {
                    key: index,
                    context: 'button',
                    disabled: disabled ||
                        isLoading ||
                        (disabledChild !== null && Utils.IsFunction(disabledChild) && disabledChild(record, isLoading, permissions)) ||
                        (Utils.IsBoolean(disabledChild) && disabledChild)
                }

                return Utils.CreateOrCloneElement(child, { ...commonProps, ...childProps })
            })}
        </TopToolbar>
    )
};

ShowActions.propTypes = {
    disabled: PropTypes.bool,
    hasList: PropTypes.bool,
    hasBack: PropTypes.bool,
    hasClone: PropTypes.bool,
    hasEdit: PropTypes.bool,
    children: PropTypes.node,
};

ShowActions.displayName = 'ShowActions';

export default ShowActions;