import React, { useEffect, useState } from 'react';

import { createTheme, ThemeProvider, } from '@mui/material/styles';

import { I18nProvider } from "ra-core";
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { QueryClientProvider, QueryClient } from 'react-query';

// domain translations
import { default as englishDomainMessages } from "./translations/en";
import { default as italianDomainMessages } from "./translations/it";

import { useParams } from 'react-router-dom';

import {
    DataProviderContext,
    I18nContextProvider,
    NotificationContextProvider,
    Notification,
} from 'react-admin';

import MainContent from './MainContent';
import { Guest } from './types';
import { dataProvider } from './providers';

const translations: any = {
    it: { ...italianDomainMessages },
    en: { ...englishDomainMessages },
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
        },
    },
})

const App = () => {
    const params = useParams();
    const id = params ? params["*"] || null : null;

    const [i18nProvider, setI18nProvider] = useState<I18nProvider>({} as I18nProvider);

    const [setupCompleted, setSetupCompleted] = useState<boolean>(false);
    const [guest, setGuest] = useState<Guest | null>(null);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#b29a89',
            },
            secondary: {
                main: '#5b3a29',
            },
        },
        typography: {
            fontFamily: [
                'Mountains of Christmas',
                'sans-serif',
            ].join(','),
        }
    });

    useEffect(() => {
        const i18nProvider = polyglotI18nProvider(
            locale => translations[locale] ? translations[locale] : translations.it,
            'it',//resolveBrowserLocale(),
            { allowMissing: true }
        );

        setI18nProvider(i18nProvider);

        if (id) {
            dataProvider.getOne<Guest>('guests', { id })
                .then(({ data }) => {
                    setGuest(data);
                })
                .catch(error => console.log(error))
                .finally(() => setSetupCompleted(true));
        }
        else
            setSetupCompleted(true);

        return () => { };
    }, [])

    if (setupCompleted) {
        return (
            <DataProviderContext.Provider value={dataProvider}>
                <QueryClientProvider client={queryClient}>
                    <I18nContextProvider value={i18nProvider}>
                        <NotificationContextProvider>
                            <ThemeProvider theme={theme}>
                                <MainContent guest={guest} setGuest={(data) => setGuest(data)} />
                                <Notification />
                            </ThemeProvider>
                        </NotificationContextProvider>
                    </I18nContextProvider>
                </QueryClientProvider>
            </DataProviderContext.Provider>
        )
    }
    else {
        return null
    }
};

export default App;