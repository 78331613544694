import * as React from 'react';
import { MouseEventHandler, ReactElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Icon from '@mui/icons-material/Delete';
import IconRestore from '@mui/icons-material/Restore';

import {
    DeleteWithConfirmButton,
    DeleteWithConfirmButtonProps,
    useRecordContext,
} from 'react-admin';

interface Props {
    className?: string;
    disabled?: boolean;
    icon?: ReactElement;
    iconRestore?: ReactElement;
    invalid?: boolean;
    label?: string;
    labelRestore?: string;
    confirmTitle?: React.ReactNode;
    confirmContent?: React.ReactNode;
    confirmTitleRestore?: React.ReactNode;
    confirmContentRestore?: React.ReactNode;
    variant?: string;
    toRestore?: boolean;
    toRestoreFn?: ((record: any) => boolean) | null;
}

export type SoftDeleteWithConfirmButtonProps = Props &
    DeleteWithConfirmButtonProps & {
        alwaysEnable?: boolean;
    };

export const SoftDeleteWithConfirmButton = (
    props: SoftDeleteWithConfirmButtonProps
) => {
    const {
        icon = defaultIcon,
        iconRestore = defaultIconRestore,
        label = 'ra.action.soft_delete',
        labelRestore = 'ra.action.restore',
        confirmTitle = 'ra.message.soft_delete_title',
        confirmContent = 'ra.message.soft_delete_content',
        confirmTitleRestore = 'ra.message.delete_title_restore',
        confirmContentRestore = 'ra.message.delete_content_restore',
        onClick,
        className,
        toRestore = false,
        toRestoreFn = null,
        ...rest
    } = props;

    const record = useRecordContext(props);

    const isToRestore: boolean = toRestoreFn !== null ? toRestoreFn(record) : toRestore;

    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        async event => {
            if (onClick) {
                onClick(event);
            }
            if (event.defaultPrevented) {
                return;
            }
            event.stopPropagation();
        },
        [onClick]
    );

    return (
        <DeleteWithConfirmButton
            label={""}
            confirmTitle={isToRestore ? confirmTitleRestore : confirmTitle}
            confirmContent={isToRestore ? confirmContentRestore : confirmContent}
            onClick={handleClick}
            className={clsx(SoftDeleteWithConfirmButtonClasses.root, className)}
            {...(rest as any)}
            redirect={false}
            mutationMode='optimistic'
            icon={isToRestore ? iconRestore : icon}
        />
    );
};

const defaultIcon = <Icon color='error' />;
const defaultIconRestore = <IconRestore color='success' />;

SoftDeleteWithConfirmButton.propTypes = {
    className: PropTypes.string,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    icon: PropTypes.element,
};

const PREFIX = 'RaSoftDeleteWithConfirmButton';

export const SoftDeleteWithConfirmButtonClasses = {
    root: `${PREFIX}-root`,
};  