import { Card, CardContent } from '@mui/material';
import { Title, useAuthenticated } from 'react-admin';

export default () => {
    useAuthenticated({ logoutOnFailure: true }); // redirects to login if not authenticated
    return (
        <Card>
            <Title title="Not Found" />
            <CardContent>
                <h1>La pagina non esiste</h1>
            </CardContent>
        </Card>
    )
};