import React, { cloneElement, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';

import {
    ArrayInput,
    TextInput,
    SimpleFormIterator,
    FormDataConsumer,
    LinearProgress,
    useTranslate,
    required,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { Utils } from 'admin/core';
import { RichTextInput } from 'admin/components';

import { AppBar, Tabs, Tab } from '@mui/material';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            className="w-100 border border-top-0 rounded-bottom pt-3"
            style={{ borderColor: '#e0e0e3' }}
            {...other}
        >
            {children}
        </div>
    );
}

const CustomForm = ({ getSource, scopedFormData = {}, formData = {}, fieldLabel, hasContent = true, activeTab, rowIndex, ...props }) => {
    const translate = useTranslate();
    const getLabel = (field) => translate(`resources.${fieldLabel.toLowerCase()}.fields.${field}`);

    if (!scopedFormData)
        return <LinearProgress />;
    else {
        return (
            <TabPanel value={activeTab} index={rowIndex}>
                {/* <span className="badge badge-lighter pb-0 col-12" noInput>
                        <h4 className="mb-0">
                            {scopedFormData.language.toUpperCase()}
                        </h4>
                    </span> */}
                <TextInput source={getSource("title")} label={getLabel("title")} validate={scopedFormData.language.toUpperCase() === "IT" ? [required()] : []} fullWidth formClassName="col-12" />
                {hasContent && <RichTextInput source={getSource("content")} label={getLabel("content")} fullWidth formClassName="col-12" />}
            </TabPanel>
        )
    }
};

export const CustomReferenceInput = ({ hasContent, ...props }) => {
    const { change } = useForm();
    const [value, setValue] = React.useState(0);
    const { values } = useFormState({ subscription: { values: true } });
    const [languages, setLangauges] = useState([]);

    useEffect(() => {
        const configuredLanguages = ['it-IT', 'en-US'];
        let finalChoices = [];
        let actualChoices = Utils.DeepFind(values, props.source) || [];
        if (Utils.IsArray(configuredLanguages)) {
            finalChoices = configuredLanguages.map(configuredLanguage => {
                const translations = actualChoices.find(x => x.language === configuredLanguage);
                return !Utils.IsEmpty(translations) ? translations :
                    {
                        language: configuredLanguage,
                        isDefaultLanguage: configuredLanguage === 'it-IT',
                        title: "",
                        content: "",
                    };
            })
        }
        change(props.source, finalChoices);
        setLangauges(configuredLanguages)
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (languages.length === 0) return <LinearProgress />;

    return (
        <>
            <AppBar color='transparent' position="static" className="shadow-none rounded-top">
                <Tabs value={value} onChange={handleChange} textColor="secondary" variant="fullWidth">
                    {
                        languages.map((language, key) => {
                            return <Tab key={key} label={language.toUpperCase()} {...a11yProps(key)} />;
                        })
                    }
                </Tabs>
            </AppBar>
            <ArrayInput {...props} label=" " className="w-100 mt-0">
                <SimpleFormIterator className="row px-0 mt-0" lineClassName="col-12" useBootstrap disableAdd disableRemove hideBorders>
                    <FormDataConsumer className="w-100">
                        {(formDataProps) => {
                            return (
                                <CustomForm {...formDataProps} activeTab={value} fieldLabel={props.source} hasContent={hasContent} />
                            );
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );
};