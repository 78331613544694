import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signOut,
} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDeQDiJkr-X2qLtv0yjTxBzgV35jlffkxM",
    authDomain: "wedding-340816.firebaseapp.com",
    projectId: "wedding-340816",
    storageBucket: "wedding-340816.appspot.com",
    messagingSenderId: "387429960459",
    appId: "1:387429960459:web:d3f39a88a576fb122cb00e",
    measurementId: "G-RWL833LR1G"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
    return signInWithPopup(auth, googleProvider).then(userCredential => {
        return Promise.resolve(userCredential);
    }).catch((error: any) => {
        return Promise.reject(error);
    });
};

const logout = () => {
    signOut(auth);
};

export {
    auth,
    signInWithGoogle,
    logout
};