import { alpha } from '@mui/material';
import { green, grey, orange, blue, lightBlue, red } from '@mui/material/colors';
import { useTranslate } from 'react-admin';

export enum CommonButtonNames {
    BulkDeleteButton = 'BulkDeleteButton',
    BulkDeleteWithConfirmButton = 'BulkDeleteWithConfirmButton',
    BulkDeleteWithUndoButton = 'BulkDeleteWithUndoButton',
    BulkExportButton = 'BulkExportButton',
    BulkUpdateButton = 'BulkUpdateButton',
    BulkUpdateWithConfirmButton = 'BulkUpdateWithConfirmButton',
    BulkUpdateWithUndoButton = 'BulkUpdateWithUndoButton',
    Button = 'Button',
    CreateButton = "CreateButton",
    DeleteButton = 'DeleteButton',
    DeleteWithConfirmButton = 'DeleteWithConfirmButton',
    DeleteWithUndoButton = 'DeleteWithUndoButton',
    EditButton = 'EditButton',
    ExportButton = 'ExportButton',
    IconButtonWithTooltip = 'IconButtonWithTooltip',
    LocalesMenuButton = 'LocalesMenuButton',
    ListButton = 'ListButton',
    PrevNextButtons = 'PrevNextButtons',
    RefreshButton = 'RefreshButton',
    RefreshIconButton = 'RefreshIconButton',
    SaveButton = 'SaveButton',
    ShowButton = "ShowButton",
    SkipNavigationButton = 'SkipNavigationButton',
    ToggleThemeButton = 'ToggleThemeButton',
    UpdateButton = 'UpdateButton',
    UpdateWithUndoButton = 'UpdateWithUndoButton',
    UpdateWithConfirmButton = 'UpdateWithConfirmButton',
}

export const GetCommonButtonInfo = (value: CommonButtonNames | string, disabled: boolean = false) => {
    const translate = useTranslate();

    let iconColor: any = grey[500];
    let label: string = "";

    switch (value) {
        case CommonButtonNames.CreateButton:
            label = "ra.action.create";
            iconColor = green[700];
            break;
        case CommonButtonNames.EditButton:
            label = "ra.action.edit";
            iconColor = orange[500];
            break;
        case CommonButtonNames.ShowButton:
            label = "ra.action.show";
            iconColor = lightBlue[500];
            break;
        case CommonButtonNames.SaveButton:
            label = "ra.action.save";
            iconColor = blue[600];
            break;
        case CommonButtonNames.DeleteWithConfirmButton:
            label = "ra.action.delete";
            iconColor = red[500];
            break;
    }

    if (disabled)
        iconColor = alpha(iconColor, 0.3);

    return {
        label: translate(label),
        iconColor,
        color: alpha(iconColor, 0.3)
    }
}