import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';

import { makeStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Icon from '@mui/icons-material/AddPhotoAlternate';

import {
    Button,
    useNotify,
    useDataProvider,
    CreateBase,
    SimpleForm,
    FileInput,
    FileField,
    ImageInput,
    ImageField,
} from 'react-admin';

import { Utils } from 'admin/core';

import Dialog from './Dialog';

const useStyles = makeStyles(theme => ({
    root: {
        width: '70vw'
    }
}));

export const MediaUploader = forwardRef(({ open, setOpen, accept, type, multiple, onSelected, ...props }, ref) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [medias, setMedias] = useState([]);

    useImperativeHandle(ref, () => ({
        async uploadMediaAsync() {
            const uploadedFiles = await Promise.all([...medias].map(async (media) => {
                const formData = new FormData();
                formData.append("file", media);

                var objToUploaded = dataProvider.create('medias', formData)
                    .then(({ data }) => {
                        return data;
                    })
                    .catch(error => {
                        return null;
                    });

                return objToUploaded;
            }).filter(x => x != null));

            if (medias.length == uploadedFiles.length)
                notify(!multiple ? 'pos.media_manager.saved' : 'pos.media_manager.saved_many');

            return uploadedFiles;
        }
    }));

    let _accept;

    if (type) {
        switch (type) {
            case 'image':
                _accept = 'image/*'
                break;
            case 'file':
            default:
                _accept = '*'
                break;
        }
    }
    else if (accept) {
        if (Array.isArray(accept)) {
            _accept = accept.join(',');
        }
        else {
            _accept = accept;
        }
    }

    const onlyImages = _accept.split(',').filter(x => x.startsWith('image/')).length == _accept.split(',').length;

    var onChange = (value) => {
        const files = value ? (Array.isArray(value) ? value : [value]) : [];
        setMedias(files);

        if (onSelected && Utils.IsFunction(onSelected))
            onSelected(files);
    }

    return (
        <CreateBase resource='medias'>
            <SimpleForm toolbar={null}>
                <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2} className={classes.root}>
                    {
                        onlyImages ?
                            <ImageInput source="file" label={false} onChange={onChange} multiple={multiple} accept={_accept} {...props}>
                                <ImageField source="src" title="title" />
                            </ImageInput> :
                            <FileInput source="file" label={false} onChange={onChange} multiple={multiple} accept={_accept} {...props}>
                                <FileField source="src" title="title" />
                            </FileInput>
                    }
                </Grid>
            </SimpleForm>
        </CreateBase>
    );
});

MediaUploader.propTypes = {
    icon: PropTypes.element,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    type: PropTypes.oneOf(['image', 'file']),
    multiple: PropTypes.bool,
};

MediaUploader.defaultProps = {
    accept: 'image/*',
    multiple: false,
}

export const MediaUploaderButton = ({ onUploaded, icon = defaultIcon, accept, type, multiple, ...props }) => {
    const mediaUploaderRef = useRef();
    const [open, setOpen] = useState(false);

    const handleOpenMediaGallery = e => {
        e.stopPropagation();
        setOpen(true);
    };

    const onConfirmed = async (e) => {
        e.stopPropagation();

        var uploadedFiles = await mediaUploaderRef.current.uploadMediaAsync();
        if (onUploaded && Utils.IsFunction(onUploaded)) {
            onUploaded(uploadedFiles);
        }
        setOpen(false);
    }

    const actions = [
        <Button onClick={onConfirmed} color="primary" label={'ra.action.confirm'} />
    ];

    return (
        <>
            <Button label={'ra.action.create'} onClick={handleOpenMediaGallery} {...props}>
                {icon}
            </Button>
            <Dialog open={open} onOpen={() => setOpen(true)} onClosed={() => setOpen(false)} title={"pos.media_manager.title"} actions={actions}>
                <MediaUploader ref={mediaUploaderRef} accept={accept} type={type} multiple={multiple} {...props} />
            </Dialog>
        </>
    );
};

const defaultIcon = <Icon />;

MediaUploaderButton.propTypes = {
    onUploaded: PropTypes.func,
    icon: PropTypes.element,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    type: PropTypes.oneOf(['image', 'file']),
    multiple: PropTypes.bool,
};

MediaUploaderButton.defaultProps = {
    accept: 'image/*',
    multiple: false,
}