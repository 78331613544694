import React, { cloneElement, memo } from 'react';

import {
    Labeled,
} from 'react-admin';

const MobileListTextField = memo(
    ({ className, resource, source, record = {}, emptyText, asStandardField = false, fullWidth = false, children, ...rest }) => {
        return (
            <Labeled resource={resource} source={source} asStandardField={asStandardField} className={fullWidth ? 'w-100' : ''}>
                {children && cloneElement(children, { ...rest })}
            </Labeled>
        )
    }
);

MobileListTextField.displayName = 'MobileListTextField';

export default MobileListTextField;
