export default {
    accessDenied: 'Mi spiace, qualcosa è andato storto',
    menu: {
        home: 'Home',
        when: 'Quando?',
        where: 'Dove?',
        rsvp: 'Conferma',
        dressCode: 'Dress Code',
        weddingList: 'Lista nozze',
    },
    countdown: {
        days: 'Giorno |||| Giorni',
        hours: 'Ora |||| Ore',
        minutes: 'Minuto |||| Minuti',
        seconds: 'Secondo |||| Secondi',
    },
    home: {
        wedding: 'Il matrimonio di',
    },
    when: {
        started: 'Si comincia!!!',
        left: 'Mancano',
        leftTo: 'alla festa'
    },
    rsvp: {
        greetings: 'Ciao',
        infoMessage: 'ci farebbe piacere condividere con te questa giornata di festa |||| ci farebbe piacere condividere con voi questa giornata di festa',
        confirm: 'Sì, non posso mancare |||| Sì, e saremo in',
        confirmMessage: 'Ci sarai? |||| Ci sarete?',
        notConfirm: 'Mi dispiace, ma non potrò esserci |||| Mi dispiace, ma non potremo esserci',
        otherInfos: 'Altre informazioni (allergie, ecc.)',
        updated: 'Modifiche salvate',
        error: 'Modifiche non salvate',
        save: 'Salva'
    },
    dressCode: {
        title: 'parole d\'ordine: COMODITÀ',
        trekkings: 'SCARPE COMODE',
        giaccaVento: 'GIACCA A VENTO',
        pants: 'PANTALONI LUNGHI',
        sunglasses: 'OCCHIALI DA SOLE',
    },
    weddingList: {
        title: 'Per fortuna non abbiamo bisogno di nulla.',
        subtitle: 'Ma ci farebbe piacere se ci aiutaste a sostenere le cause che ci stanno più a cuore.',
        cit: 'I diritti degli uomini devono essere di tutti gli uomini, proprio di tutti, sennò chiamateli privilegi',
        citAuth: 'G. Strada'
    }
}