import React, { useEffect, useState } from 'react';

import {
    AppBar,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    Fab,
    Fade,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
    Button,
    Slide,
    useMediaQuery,
    useScrollTrigger,
    alpha,
    Paper
} from '@mui/material';

import { keyframes } from '@mui/system';

import { useTheme } from '@mui/material/styles';

import LockIcon from '@mui/icons-material/Lock';
import MenuIcon from '@mui/icons-material/Hiking';
import CloseIcon from '@mui/icons-material/Close';
import DownIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Link } from "react-scroll";

import { Utils } from 'admin/core';
import { useTranslate } from 'react-admin';
import { Guest, NavSection } from './types';
import { views } from './pages';

import borderTopLeftImg from './img/border-top-left.png';
import borderBottomRightImg from './img/border-bottom-right.png';
import ringsImg from './img/rings_brown.png';

interface Props {
    guest?: Guest | null;
    setGuest: (data: Guest) => void
}

interface ScrollProps {
    children: React.ReactElement;
    id?: string;
}

export type DrawerAppBarProps = Props;

function HideOnScroll(props: ScrollProps) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({});

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

function ScrollTop(props: ScrollProps) {
    const { id, children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: window.innerHeight * (views.length - 2),
    });

    return (
        <Fade in={trigger}>
            <Box
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <Link
                    to={id}
                    spy={true}
                    smooth={true}
                    duration={500}
                >
                    {children}
                </Link>
            </Box>
        </Fade>
    );
}

export default function MainContent(props: Props) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { guest, setGuest } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const translate = useTranslate();
    const [navItems, setNavItems] = useState<NavSection[]>([]);

    useEffect(() => {
        setNavItems(views);
        return () => { };
    }, [])

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box sx={{
            height: '100%',
            textAlign: 'center',
            color: theme.palette.secondary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.25),
        }}>
            <Paper square elevation={4} sx={{ backgroundColor: theme => theme.palette.primary.main }}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={8} display='flex' justifyContent="center">
                        <Typography variant="h6" sx={{ fontSize: '5vh', display: 'flex', alignItems: 'center' }}>
                            Cele
                            <img src={ringsImg} style={{ margin: '0 5px', height: '4vh' }} />
                            Ale
                        </Typography>
                    </Grid>
                    <Grid item xs={2} display='flex' justifyContent="end">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
            <Divider />
            <List>
                {navItems.map((navItem) => (
                    <div key={navItem.id}>
                        <ListItem sx={{ justifyContent: 'center' }} disablePadding>
                            <Link
                                to={navItem.id}
                                spy={true}
                                smooth={true}
                                duration={500}
                                onClick={() => { setTimeout(handleDrawerToggle, 180); }}
                            >
                                <ListItemButton sx={{ textAlign: 'center' }}>
                                    <ListItemText
                                        disableTypography
                                        primary={<Typography variant="body2" sx={{ fontSize: '4vh' }}>{translate(navItem.name)}</Typography>}
                                    />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <Divider variant="inset" component="li" sx={{ mx: 8 }} />
                    </div>
                ))}
            </List>
        </Box>
    );

    if (!guest) {
        return (
            <Box
                display='flex'
                justifyContent="center"
                alignItems="center"
                flexDirection='column'
                sx={{
                    height: '100vh',
                    width: '100vw',
                    backgroundColor: alpha(theme.palette.primary.main, 0.25),
                }}
            >
                <LockIcon sx={{ fontSize: '5vh', color: theme.palette.secondary.main }} />
                <Typography sx={{ fontSize: '5vh', mt: 3 }} textAlign='center'>
                    {translate('accessDenied')}
                </Typography>
            </Box>
        )
    }

    return (
        <Box>
            <CssBaseline />
            <HideOnScroll {...props}>
                <AppBar component="nav" sx={{ backgroundColor: theme.palette.primary.main }}>
                    <Toolbar>
                        {
                            isSmall ? (
                                <Grid container>
                                    <Grid item xs={12} display='flex' justifyContent="center" alignItems="center">
                                        <IconButton
                                            aria-label="open drawer"
                                            edge="start"
                                            onClick={handleDrawerToggle}
                                            sx={{ color: theme.palette.secondary.main }}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ) :
                                (
                                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                                        <Grid item xs={4}>
                                            <Typography variant="h6" sx={{ fontSize: '3vh', display: 'flex', alignItems: 'center' }}>
                                                Cele
                                                <img src={ringsImg} style={{ margin: '0 5px', height: '3vh' }} />
                                                Ale
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} display='flex' justifyContent="end">
                                            <Box>
                                                {navItems.map((navItem) => (
                                                    <Link
                                                        key={navItem.id}
                                                        to={navItem.id}
                                                        spy={true}
                                                        smooth={true}
                                                        duration={500}
                                                    >
                                                        <Button sx={{ fontSize: '2vh', color: theme.palette.secondary.main, fontWeight: '900' }}>
                                                            {translate(navItem.name)}
                                                        </Button>
                                                    </Link>
                                                ))}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                        }
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <nav>
                <Drawer
                    anchor="top"
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100vw', height: '100vh' },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>

            {
                navItems.map((navItem, index) => (
                    <Box
                        key={navItem.id}
                        id={navItem.id}
                        sx={{
                            height: '100vh',
                            width: '100%',
                            backgroundColor: alpha(theme.palette.primary.main, 0.25),
                            position: 'relative'
                        }}
                    >
                        {index === 0 && <Toolbar />}
                        <Box
                            sx={{
                                p: 0,
                                height: index === 0 ? 'calc(100% - 56px)' : '100%',
                                backgroundImage: `url(${borderTopLeftImg}), url(${borderBottomRightImg})`,
                                backgroundSize: isSmall ? '45vh' : '50vh',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'top left, bottom right',
                                backgroundPositionX: '-5, 5',
                            }}
                        >
                            <Box sx={{
                                height: '95%',
                                paddingTop: '5%',
                            }}>
                                {Utils.CreateOrCloneElement(navItem.content, { guest, setGuest })}
                            </Box>
                            {
                                <Box
                                    sx={{
                                        position: 'relative',
                                        height: '4%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    {
                                        index < navItems.length - 1 &&
                                        <Link
                                            activeClass="active"
                                            to={navItems[index + 1].id}
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                            style={{
                                                position: 'absolute',
                                                left: '5vw',
                                                bottom: '1vh'

                                            }}
                                        >
                                            <IconButton sx={{
                                                color: theme.palette.secondary.main,
                                                animation: `${keyframes`from { opacity: 0; } to { opacity: 1; }`} 3s ease-in-out infinite`,
                                            }} >
                                                <DownIcon sx={{ fontSize: { xs: '5vh', sm: '10vh' } }} />
                                            </IconButton >
                                        </Link>
                                    }
                                </Box>
                            }
                        </Box>
                    </Box>
                ))
            }
            {
                navItems && navItems.length > 0 &&
                <ScrollTop id={navItems[0].id} {...props} >
                    <Fab color='primary' size="small" aria-label="scroll back to top">
                        <KeyboardArrowUpIcon />
                    </Fab>
                </ScrollTop>
            }
        </Box>
    );
}