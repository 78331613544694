import * as React from 'react';

import { Grid } from '@mui/material';

import {
    Labeled,
    RichTextField,
    useRecordContext,
} from 'react-admin';

const CustomShowExpand = () => {
    const record = useRecordContext();

    if (!record) return null;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <Labeled source="otherInfos">
                    <RichTextField source="otherInfos" fullWidth />
                </Labeled>
            </Grid>
        </Grid>
    );
};

export default CustomShowExpand;