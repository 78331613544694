// in src/comments.js
import React, { Children, cloneElement, isValidElement } from 'react';
import { Grid, Card, CardHeader, CardActions, CardContent, Divider } from '@mui/material';

import {
    EditButton,
    DeleteWithConfirmButton,
} from 'react-admin';

import MobileListTextField from '../field/MobileListTextField';
import { ListRowActions } from './Actions';

const MobileList = (props) => {
    const { ids, data, basePath, resource, children, title = false, fieldAsTitle = false, asStandardField } = props;

    if (!ids || !data) return null;

    return (
        <div>
            {ids.map(id => {
                const record = data[id];
                return (
                    <div key={id} >
                        <Card>
                            {
                                title || fieldAsTitle && <CardHeader
                                    title={
                                        <div>
                                            <div>
                                                <h2>{title || `${record[fieldAsTitle]}`}</h2>
                                            </div>
                                        </div>
                                    }
                                />
                            }
                            <CardContent>
                                <Grid container spacing={1}>
                                    {children && Children.map(children, (field, key) => {
                                        if (field && isValidElement(field)) {
                                            const { xs = 12, md = 6, source, fullWidth, ...fieldProps } = field.props;
                                            return (
                                                <Grid key={key} item xs={xs} md={md}>
                                                    <MobileListTextField
                                                        asStandardField={asStandardField}
                                                        basePath={basePath}
                                                        resource={resource}
                                                        record={record}
                                                        source={source}
                                                        fullWidth={fullWidth}
                                                    >
                                                        {cloneElement(field, { ...field.props, resource, record })}
                                                    </MobileListTextField>
                                                </Grid>
                                            )
                                        }
                                        else {
                                            return null;
                                        }
                                    })}
                                </Grid>
                            </CardContent>
                            <CardActions disableSpacing className={"justify-content-end"}>
                                <ListRowActions resource={resource} basePath={basePath} record={record}>
                                    <EditButton />
                                    <DeleteWithConfirmButton />
                                </ListRowActions>
                            </CardActions>
                        </Card>
                        <Divider />
                    </div>
                )
            })}
        </div>
    );
};

MobileList.defaultProps = {
    data: {},
    ids: [],
};

MobileList.displayName = 'MobileList';

export default MobileList;