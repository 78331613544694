import * as React from 'react';
import { Grid } from '@mui/material';
import { Authenticated } from 'react-admin';

import { Welcome } from './Welcome';

export default () => {
    return (
        <Authenticated>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Welcome />
                </Grid>
            </Grid>
        </Authenticated>
    )
};