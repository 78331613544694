import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
    ImageInput,
    ImageField,
    LinearProgress,
    useInput,
    useNotify,
    fetchUtils
} from 'react-admin';

import { Utils } from 'admin/core';
import { jwtProvider } from 'admin/providers';

import FileUploadPlaceholder from '../layout/FileUploadPlaceholder'
import MediaGalleryButton from '../buttons/MediaGalleryButton'

const apiEndpoint = Utils.GetENV('API_ENDPOINT');

const FileInput = ({ useMediaGallery = false, mediaGalleryText = 'pos.media_manager.title_field', accept = 'image/*', type = "image", multiple = false, ...props }) => {
    const notify = useNotify();
    const { field } = useInput(props);
    const [loading, setLoading] = useState(false);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [hasFiles, setHasFiles] = useState(currentFiles && currentFiles.length > 0);

    const isFieldValid = (field.value ? (Array.isArray(field.value) ? field.value : [field.value]) : []).filter(x => !Utils.IsObject(x)).length === 0;

    useEffect(() => {
        const decodedToken = jwtProvider.getToken(true);
        let currentCulture = "it-IT";

        if (decodedToken)
            currentCulture = decodedToken.culture;

        const currentUrls = field.value ? (Array.isArray(field.value) ? field.value : [field.value]) : [];

        if (currentUrls.length > 0) {
            setLoading(true);

            let urls = currentUrls.filter(x => !Utils.IsObject(x));

            if (urls.length > 0) {
                const token = jwtProvider.getToken();

                const customHeaders = new Headers({ Accept: 'application/json' });

                customHeaders.set('Accept-Language', currentCulture);
                customHeaders.set('Authorization', `Bearer ${token}`);

                fetchUtils.fetchJson(`${apiEndpoint}/medias/bysrc`, {
                    method: 'POST',
                    body: JSON.stringify(urls),
                    headers: customHeaders
                }).then(({ json }) => {
                    if (Array.isArray(field.value))
                        field.onChange(json);
                    else
                        field.onChange(json[0]);

                    setCurrentFiles(json);
                    setHasFiles(true);
                }).catch(error => {
                    console.error(error);
                    notify('Error', 'warning');
                    setCurrentFiles([]);
                    setHasFiles(false);
                }).finally(() => {
                    setLoading(false);
                });
            }
            else
                setLoading(false);
        }
        return () => { };
    }, [field])

    var onUploaded = (value) => {
        const files = value ? (Array.isArray(value) ? value : [value]) : [];
        setHasFiles(files && files.length > 0);
    }

    const onMediaGallerySelected = (src, selectedMedia) => {
        let filesAny = (selectedMedia ? (Array.isArray(selectedMedia) ? selectedMedia : [selectedMedia]) : []).length > 0;
        setHasFiles(filesAny);
        field.onChange(selectedMedia);
    }

    if (!isFieldValid || loading) { return <LinearProgress />; }

    if (useMediaGallery) {
        return !hasFiles ?
            (
                <MediaGalleryButton onSelected={onMediaGallerySelected} label={mediaGalleryText} accept={accept} type={type} multiple={multiple} />
            ) :
            (
                <ImageInput placeholder={<FileUploadPlaceholder hideOnFileLoaded hasFiles={hasFiles} multiple={multiple} />} helperText={false} onChange={onUploaded} multiple={multiple} {...props} label={false} sx={{ marginTop: '-16px' }}>
                    <ImageField source="src" title="title" />
                </ImageInput>
            )
    }
    else {
        return !hasFiles ?
            (
                <ImageInput placeholder={<FileUploadPlaceholder hideOnFileLoaded hasFiles={hasFiles} multiple={multiple} />} onChange={onUploaded} multiple={multiple} {...props}>
                    <ImageField source="src" title="title" />
                </ImageInput>
            ) :
            (
                <ImageInput placeholder={<FileUploadPlaceholder hideOnFileLoaded hasFiles={hasFiles} multiple={multiple} />} helperText={false} onChange={onUploaded} multiple={multiple} {...props} label={false} sx={{ marginTop: '-16px' }}>
                    <ImageField source="src" title="title" />
                </ImageInput>
            )
    }
};

FileInput.propTypes = {
    useMediaGallery: PropTypes.bool,
    mediaGalleryText: PropTypes.string,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    type: PropTypes.oneOf(['image', 'file']),
    multiple: PropTypes.bool,
};

FileInput.displayName = 'FileUploadPlaceholder';

export default FileInput;