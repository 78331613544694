import { useEffect } from 'react';

import {
    useLocaleState,
    useTranslate
} from 'react-admin';

import {
    Box,
    Grid,
    Stack,
    Typography,
    alpha,
} from '@mui/material';

import Countdown from 'react-countdown';
import { Utils } from 'admin/core';

import bottleImg from '../img/bottle.png';

interface Props {
}

export type HomeProps = Props;

type CountDownBoxProps = {
    typeName: string,
    value: any,
};

const CountDownBox = (props: CountDownBoxProps) => {
    const translate = useTranslate();

    const {
        typeName,
        value
    } = props;

    return (
        <Box
            sx={{
                width: { xs: '15vh', sm: 300 },
                height: { xs: '15vh', sm: 300 },
                padding: '25px',
                backgroundColor: alpha('#ffffff', 0.45),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Typography variant='body1' textAlign='center' fontSize={{ xs: 40, sm: 65 }} color='secondary'>
                {value}
            </Typography>
            <Typography variant='body1' textAlign='center' fontSize={{ xs: 22, sm: 50 }} color='secondary'>
                {translate(`countdown.${typeName}`, { smart_count: value })}
            </Typography>
        </Box>
    )
}

const When = (props: HomeProps) => {
    const [locale, setLocale] = useLocaleState();
    const translate = useTranslate();

    const weddingDate = new Date(2024, 5, 28, 11, 30);
    const dateParts = Utils.GetDateParts(weddingDate);

    const {
        ...rest
    } = props;

    useEffect(() => {
    }, []);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <>
                <Stack spacing={2} justifyContent="space-evenly" alignItems="center" sx={{ height: '10vh', display: { xs: 'none', sm: 'flex' } }}>
                    <Typography variant='body1' textAlign='center' fontSize={'10vh'} color='secondary'>
                        {`${dateParts?.weekday} ${dateParts?.day} ${Utils.GetMonthName(dateParts?.month, locale)} ${dateParts?.year}`}
                    </Typography>
                </Stack>
                <Stack spacing={0} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-evenly" alignItems="center" sx={{ height: '100%' }}>
                    {
                        completed ?
                            <>
                                <Typography variant='body1' textAlign='center' fontSize={50} color='secondary'>
                                    {translate('when.started')}
                                </Typography>
                                <Box
                                    component="img"
                                    src={bottleImg}
                                    sx={{
                                        height: { xs: '45vh', sm: '50vh' },
                                    }}
                                />
                            </>
                            :
                            <>
                                <Stack spacing={0} justifyContent="space-evenly" alignItems="center" sx={{ height: '10vh', display: { sm: 'none' } }}>
                                    <Typography variant='body1' textAlign='center' fontSize={{ xs: '5vh', sm: '4vh' }} color='secondary'>
                                        {`${dateParts?.weekday}`}
                                    </Typography>
                                    <Typography variant='body1' textAlign='center' fontSize={{ xs: '6vh', sm: '5vh' }} color='secondary'>
                                        {`${dateParts?.day} ${Utils.GetMonthName(dateParts?.month, locale)} ${dateParts?.year}`}
                                    </Typography>
                                    <Typography variant='body1' textAlign='center' fontSize={{ xs: '5vh', sm: '4vh' }} color='secondary'>
                                        {`h. ${dateParts?.hour}:${dateParts?.minute}`}
                                    </Typography>
                                </Stack>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} display='flex' justifyContent="center" alignItems="center" >
                                        <Typography variant='body1' textAlign='center' fontSize={{ xs: '4vh', sm: '5vh' }} color='secondary'>
                                            {translate('when.left')}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6} sm={3} display='flex' justifyContent={{ xs: "end", sm: 'center' }} alignItems="center" >
                                        <CountDownBox typeName='days' value={days} />
                                    </Grid>
                                    <Grid item xs={6} sm={3} display='flex' justifyContent={{ xs: "start", sm: 'center' }} alignItems="center" >
                                        <CountDownBox typeName='hours' value={hours} />
                                    </Grid>

                                    <Grid item xs={6} sm={3} display='flex' justifyContent={{ xs: "end", sm: 'center' }} alignItems="center" >
                                        <CountDownBox typeName='minutes' value={minutes} />
                                    </Grid>

                                    <Grid item xs={6} sm={3} display='flex' justifyContent={{ xs: "start", sm: 'center' }} alignItems="center" >
                                        <CountDownBox typeName='seconds' value={seconds} />
                                    </Grid>

                                    <Grid item xs={12} display='flex' justifyContent="center" alignItems="center" >
                                        <Typography variant='body1' textAlign='center' fontSize={{ xs: '4vh', sm: '5vh' }} color='secondary'>
                                            {translate('when.leftTo')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                    }
                </Stack>
            </>
        )
    };

    return (
        <Countdown date={weddingDate} renderer={renderer} />
    );
}

export default When;