export default {
    name: "guests",
    resources: {
        name: 'Ospite |||| Ospiti',
        forcedCaseName: 'Ospite |||| Ospiti',
        fields: {
            name: "Name",
            message: "Message",
            confirmed: "Confirmed",
            otherInfos: "OtherInfos",
            quantity: "Quantity"
        },
    },
    global: {
        menu: {
            name: 'Ospiti',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Ospite "%{title}"',
        },
        action: {
            sent: 'Sent',
            not_sent: 'Not Sent',
        },
    },
};