import Icon from '@mui/icons-material/People';
import CustomCreate from './CustomCreate';
import CustomEdit from './CustomEdit';
import CustomList from './CustomList';
import CustomShow from './CustomShow';

export default {
    list: CustomList,
    create: CustomCreate,
    edit: CustomEdit,
    show: CustomShow,
    icon: Icon,
};