import React, { useMemo } from 'react';

import { Grid, Divider } from '@mui/material';

import {
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    BooleanInput,
    required,
} from 'react-admin';

import { CreateActions, CreateToolbar, RichTextInput } from 'admin/components';
import { transformData } from './transformData';

const CustomCreate = ({ permissions, options, ...props }) => {
    const defaultValues = useMemo(() => ({ sent: false }), []);
    return (
        <Create redirect="list" actions={<CreateActions />} transform={transformData} {...props}>
            <SimpleForm toolbar={<CreateToolbar hasSaveAndAdd={false} />} defaultValues={defaultValues} >
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={4}>
                        <TextInput source="name" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <NumberInput source="quantity" min={1} validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <RichTextInput source="message" fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <BooleanInput source="confirmed" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider flexItem />
                    </Grid>

                    <Grid item xs={12}>
                        <RichTextInput source="otherInfos" fullWidth />
                    </Grid>

                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default CustomCreate;
