import React, { useRef, ReactNode } from 'react';
import { ReactElement } from 'react';
import PropTypes from 'prop-types';

import Icon from '@mui/icons-material/Add';

import {
    RaRecord,
    useTranslate,
    SaveContextProvider,
} from 'react-admin';

import { DialogButton } from 'admin/components';
import { DialogButtonActions } from '../buttons/DialogButton';

const FormInDialogButton = <RecordType extends RaRecord = any>(
    props: Props<RecordType>
) => {
    const {
        icon = defaultIcon,
        label = 'ra.action.add',
        title,
        resource,
        children,
        showLabel = false
    } = props;

    const dialogButtonRef = useRef<DialogButtonActions>(null);

    const translate = useTranslate();

    const save = () => {
        if (dialogButtonRef.current)
            dialogButtonRef.current.close();
    };

    const saving = false;
    const mutationMode = "pessimistic";

    if (!resource)
        throw new Error('You must provide the resource name');

    let finalTitle = title;

    if (!title)
        finalTitle = `${translate('ra.action.create')} ${translate(`resources.${resource}.name`, { smart_count: 1 })}`;

    return (
        <DialogButton ref={dialogButtonRef} icon={icon} label={showLabel ? label : ''} dialogTitle={finalTitle} >
            <SaveContextProvider value={{ save, saving, mutationMode }}>
                {children}
            </SaveContextProvider>
        </DialogButton>
    );
};

const defaultIcon = <Icon />;

interface Props<RecordType extends RaRecord = any> {
    resource: string;
    icon?: ReactElement;
    label?: string;
    title?: string;
    children: ReactNode;
    showLabel?: Boolean;
}

FormInDialogButton.propTypes = {
    resource: PropTypes.string.isRequired,
    icon: PropTypes.element,
    label: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.element.isRequired,
    showLabel: PropTypes.bool,
};

export default FormInDialogButton;
