import React, { Children, ReactNode } from 'react';
import PropTypes from 'prop-types';

import {
    ListButton,
    ShowButton,
    TopToolbar,
    useEditContext,
    usePermissions,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { Utils } from 'admin/core';

import ArrowBack from '@mui/icons-material/ArrowBack';
import { BackButton } from '../../buttons';

interface Props {
    children?: ReactNode[] | null;
    disabled?: boolean;
    hasList?: boolean;
    hasBack?: boolean;
    hasShow?: boolean;
}

export type EditActionsProps = Props;

const EditActions = (props: EditActionsProps) => {
    const {
        disabled = false,
        hasList = false,
        hasBack = true,
        hasShow = true,
        children = [],
    } = props;

    const { permissions } = usePermissions();
    const editProps = useEditContext();

    const { isLoading, record } = editProps;

    return (
        <TopToolbar>
            {hasList && !hasBack && <ListButton icon={<ArrowBack />} label={"ra.action.back"} disabled={isLoading} />}
            {hasBack && <BackButton disabled={isLoading} />}
            {hasShow && <ShowButton />}
            {Children.map(children, (child: any, index) => {
                const { disabled: disabledChild, ...childProps } = child.props

                const commonProps = {
                    key: index,
                    context: 'button',
                    disabled: disabled ||
                        isLoading ||
                        (disabledChild !== null && Utils.IsFunction(disabledChild) && disabledChild(record, isLoading, permissions)) ||
                        (Utils.IsBoolean(disabledChild) && disabledChild)
                }

                return Utils.CreateOrCloneElement(child, { ...commonProps, ...childProps })
            })}
        </TopToolbar>
    )
};

EditActions.propTypes = {
    disabled: PropTypes.bool,
    hasList: PropTypes.bool,
    hasBack: PropTypes.bool,
    hasShow: PropTypes.bool,
    children: PropTypes.node,
};

EditActions.displayName = 'EditActions';

export default EditActions;