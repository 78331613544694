import { useMediaQuery } from '@mui/material';

import SentIcon from '@mui/icons-material/CheckCircle';
import NotSentIcon from '@mui/icons-material/CheckCircleOutline';

import {
    List,
    SimpleList,
    Datagrid,
    EditButton,
    DeleteWithConfirmButton,
    ShowButton,
    TextField,
    BooleanField,
    Filter,
    SearchInput,
    NumberField,
    useRecordContext,
    useUpdate,
    NullableBooleanInput,
} from 'react-admin';

import { CustomButton, ListActions, ListBulkActions, ListRowActions } from 'admin/components';

import CustomShowExpand from './CustomShowExpand';
import { CopyButton } from './CopyButton';
import { Utils } from 'admin/core';
import { OpenButton } from './OpenButton';

const ListFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <NullableBooleanInput source="confirmed" alwaysOn />
        {/* <BooleanInput source="xxx" />
        <ReferenceInput source="xxxId" reference="xxx" label="resources.xxx.fields.xxx">
            <SelectInput source="xxx" />
        </ReferenceInput>
        <NumberInput source="xxx" /> */}
    </Filter>
);

const SentButton = () => {
    const record = useRecordContext();
    const [update] = useUpdate();

    const handleClick = () => {
        update('guests', { id: record.id, data: { sent: !record.sent }, previousData: record }, { onSuccess: (data) => record.sent = data.sent })
    }

    return (
        <CustomButton
            label={record?.sent ? 'guests.action.sent' : 'guests.action.not_sent'}
            onClick={handleClick}
            icon={record?.sent ? <SentIcon color='success' /> : <NotSentIcon color='success' />}
        />
    )
}

const CustomList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            {...props}
            actions={<ListActions />}
            filters={<ListFilter />}
            filterDefaultValues={{}}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={50}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.name}`}
                    secondaryText={record => `${record.quantity}`}
                    tertiaryText={record => <>{record?.confirmed ? <SentIcon color='success' /> : <NotSentIcon color='success' />}<OpenButton /></>}
                    linkType="edit"
                />
            ) : (
                <Datagrid bulkActionButtons={<ListBulkActions />} expand={<CustomShowExpand />} expandSingle isRowExpandable={row => !Utils.IsEmpty(row.otherInfos)} optimized>
                    <TextField source="name" />
                    <TextField source="message" />
                    <BooleanField source="confirmed" />
                    <NumberField source="quantity" />
                    <ListRowActions>
                        <CopyButton />
                        <SentButton />
                        <OpenButton />
                        <EditButton />
                        <DeleteWithConfirmButton />
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
