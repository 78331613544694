import React, { useEffect } from 'react';

import { keyframes } from '@mui/system';
import ClickIcon from '@mui/icons-material/TouchApp';

import {
    Link,
    useTranslate
} from 'react-admin';

import {
    Box,
    Stack,
    Typography
} from '@mui/material';

import emergencyImg from '../img/emergency.jpg';
import msfImg from '../img/msf.jpg';
import liberaImg from '../img/libera.jpg';
import { red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

interface Props {
}

export type WeddingListProps = Props;

const Icon = styled(ClickIcon)(({ theme }) => ({
    color: red[400],
    fontSize: 24,
    position: 'absolute',
    bottom: '-1vh',
    right: '-1vw',
    animation: `${keyframes`from { opacity: 0; } to { opacity: 1; }`} 2s ease-in-out infinite`,
}));

const MyImg = (props: any) => {
    const {
        img,
        url
    } = props;

    return (
        <Link to={url} target='_blank' sx={{ position: 'relative', height: '10vh' }}>
            <Box
                component="img"
                src={img}
                sx={{
                    margin: 0,
                    height: '10vh',
                }}
            />
            <Icon />
        </Link>
    )
}

const WeddingList = (props: WeddingListProps) => {
    const {
        ...rest
    } = props;

    const translate = useTranslate();

    useEffect(() => {
    }, []);

    return (
        <Stack spacing={2} justifyContent="start" alignItems="center" sx={{ height: '95%', paddingTop: { xs: '15%', sm: 0 }, paddingX: { xs: 8, sm: 14 } }}>
            <Typography variant='body2' fontSize={{ xs: '3vh', sm: '5vh' }} textAlign='center'>
                {translate('weddingList.title')}
            </Typography>
            <Typography variant='body2' fontSize={{ xs: '3vh', sm: '5vh' }} textAlign='center'>
                {translate('weddingList.subtitle')}
            </Typography>
            <MyImg img={emergencyImg} url={"https://regalisolidali.emergency.it/lista-regali/matrimonio-celeste-e-alessio/"} />
            <MyImg img={msfImg} url={"https://bottegasolidale.medicisenzafrontiere.it/categorie/regali-virtuali"} />
            <MyImg img={liberaImg} url={"https://www.libera.it/schede-15-dona"} />
            <Box display='flex' justifyContent="center" alignItems="center" sx={{ width: '100%', pt: 4 }}>
                <Typography variant='body2' fontStyle='italic' fontSize={{ xs: '2.5vh', sm: '4vh' }} textAlign='center'>
                    "{translate('weddingList.cit')}"
                </Typography>
            </Box>
            <Box display='flex' justifyContent="end" alignItems="start" sx={{ width: '100%' }}>
                <Typography variant='body2' fontWeight='bold' fontSize={{ xs: '2vh', sm: '4vh' }}>
                    "{translate('weddingList.citAuth')}"
                </Typography>
            </Box>
        </Stack>
    );
}

export default WeddingList;