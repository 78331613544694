export default {
    name: "userprofile",
    resources: {
        name: 'Profilo Utente',
        forcedCaseName: 'Profilo Utente',
        fields: {
            firstName: "Nome",
            lastName: "Cognome",
            oldPassword: "Vecchia Password",
            newPassword: "Nuova Password",
            repeatNewPassword: "Conferma Nuova Password",
        },
    },
    global: {
        menu: {
            name: 'Profilo Utente',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Il mio profilo',
        },
        tabs: {
            profile: {
                title: 'Generali',
            },
            changepassword: {
                title: 'Cambio Password',
            },
        },
        validation: {
            newPassword: 'Non deve coincidere con la vecchia password',
            repeatPassword: 'Deve coincidere con la nuova password',
        },
        action: {
            updated: 'Password aggiornata',
            not_updated: 'Password non aggiornata',
            wrong: 'Vecchia password errata',
            request_error: 'Errore nella richiesta',
        },
    },
};