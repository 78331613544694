import { NavSection } from '../types';

import Home from './Home';
import When from './When';
import Where from './Where';
import DressCode from './DressCode';
import WeddingList from './WeddingList';
import Rsvp from './Rsvp';

export const views: NavSection[] = [
    {
        id: 'home',
        name: 'menu.home',
        content: <Home />
    },
    {
        id: 'when',
        name: 'menu.when',
        content: <When />
    },
    {
        id: 'where',
        name: 'menu.where',
        content: <Where />
    },
    {
        id: 'rsvp',
        name: 'menu.rsvp',
        content: <Rsvp />
    },
    {
        id: 'dressCode',
        name: 'menu.dressCode',
        content: <DressCode />
    },
    {
        id: 'weddingList',
        name: 'menu.weddingList',
        content: <WeddingList />
    }
]