import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeStyles } from "@mui/styles";
import { Typography } from '@mui/material';

import {
    useTranslate,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

const useStyles = makeStyles({
    root: {},
    title: {
        backgroundColor: 'rgba(248, 246, 249, 1)',
        border: '1px solid rgba(204, 204, 204, 1)',
        borderLeft: 'none',
        borderRight: 'none',
        fontSize: 18,
        height: 60,
        position: 'relative',
        left: -45,
        width: 'calc(100% + 90px)',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        fontWeight: '400',
        "&.disabled": {
            opacity: .7,
        },
        "&.inTab": {
            left: -31,
            width: 'calc(100% + 62px)',
        }
    }
});

const GroupedLayout = (props) => {
    const { title, as, variant, className, disabled, inTab, children } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <>
            <div className={classnames(classes.title, className, disabled ? 'disabled' : '', inTab ? 'inTab' : '')}>
                <Typography component={as} variant={variant}>
                    {translate(title)}
                </Typography>
            </div>
            {
                children && Children.map(children, cloneElement)
            }
        </>
    );
};

GroupedLayout.propTypes = {
    title: PropTypes.string,
    as: PropTypes.string,
};

GroupedLayout.defaultProps = {
    title: "",
    as: "span",
    variant: "body1",
};

export default GroupedLayout;