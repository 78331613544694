//import __Base__ from './__Base__';
import Users from './Users';
import Medias from './Medias';

import Guests from './Guests';

export default {
    // Base: {
    //     name: "base", permission: "*", ...__Base__
    // },

    Guests: {
        name: "guests", permission: "admin", ...Guests
    },

    Users: {
        name: "users", permission: "admin", ...Users
    },
    
    Medias: {
        name: "medias", permission: "admin", ...Medias
    },

    // GROUPED RESOURCES
    //...Utils.BuildResources(Polizze, true, 'tablesPolizze'),
};