import React from 'react';

import {
    Grid,
} from '@mui/material';

import { makeStyles } from "@mui/styles";
import HistoryIcon from '@mui/icons-material/History';

import { Login, LoginForm, MenuItemLink, useTranslate, useLogin, useSafeSetState } from 'react-admin';

import SocialLogin from "./SocialLogin";

const useStyles = makeStyles(
    (theme) => ({
        root: {
            flexGrow: 1,
            maxWidth: '300px',
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        control: {
            padding: theme.spacing(2),
        },
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'RaLoginForm' }
);

const CustomLoginForm = props => {
    const { hasResetPassword } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const [socialLoading, setSocialLoading] = useSafeSetState(null);
    const translate = useTranslate();
    const classes = useStyles(props);

    return (
        <Grid container className={classes.root} spacing={0}>
            {
                !socialLoading &&
                <Grid item xs={12} sx={{ '& .RaLoginForm-content': { padding: '8px 16px' }, '& button': { marginTop: '0px !important' } }}>
                    <LoginForm {...props} />
                </Grid>
            }
            {hasResetPassword && (
                <Grid item xs={12}>
                    <MenuItemLink
                        key={"resetPassword"}
                        to={'/resetPassword'}
                        primaryText={translate('ra.auth.forgot_password')}
                        leftIcon={<HistoryIcon />}
                        dense={true}
                        className="justify-content-center"
                        notInSidebar={true}
                    />
                </Grid>
            )}
            {/* <SocialLogin  {...props} loading={loading} setSocialLoading={setSocialLoading} /> */}
        </Grid>
    )
};

const LoginPage = props => (
    <Login {...props}>
        <CustomLoginForm {...props} />
    </Login>
);

export default LoginPage;