import * as React from 'react';

import { Grid, Divider } from '@mui/material';

import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    BooleanInput,
    required,
} from 'react-admin';

import { EditActions, EditToolbar, RichTextInput } from 'admin/components';

import CustomTitle from './CustomTitle';

import { transformData } from './transformData';

const CustomEdit = () => (
    <Edit redirect="list" actions={<EditActions />} title={<CustomTitle />} transform={transformData}>
        <SimpleForm toolbar={<EditToolbar />} >
            <Grid container spacing={2}>

                <Grid item xs={12} sm={4}>
                    <TextInput source="name" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <NumberInput source="quantity" min={1} validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <RichTextInput source="message" fullWidth />
                </Grid>

                <Grid item xs={12} sm={2}>
                    <BooleanInput source="confirmed" fullWidth />
                </Grid>
                <Grid item xs={12} sm={10}>
                </Grid>

                <Grid item xs={12}>
                    <Divider flexItem />
                </Grid>

                <Grid item xs={12}>
                    <RichTextInput source="otherInfos" fullWidth />
                </Grid>

            </Grid>
        </SimpleForm>
    </Edit>
);

export default CustomEdit;
