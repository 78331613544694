import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

const sizesMap = {
    'default': { size: 25, extraSize: 13 },
    'inherit': { size: 25, extraSize: 13 },
    'small': { size: 17, extraSize: 10 },
    'large': { size: 25, extraSize: 15 },
}

const extraIconStyle = {
    lineHeight: 0,
    position: 'absolute',
    textShadow: 'rgb(255 255 255) 0.75px 0px 0.5px, rgb(255 255 255) 0px 0.75px 0.5px, rgb(255 255 255) - 0.75px 0px 0.5px, rgb(255 255 255) 0px - 0.75px 0.5px',
    top: -4,
    right: -4,
    backgroundColor: 'var(--dark)',
    borderRadius: 8,
    width: '75%',
    height: '75%',
    display: 'flex',
    justifyContent:' center',
    alignItems: 'center',
    transform: 'scale(0.8)'
}

const ComposedIcon = ({
    icon,
    extraIcon,
    size = 'small',
    color = 'inherit',
    position = 'bottom-end',
}) => {
    return (
        <div style={{
            position: 'relative',
            cursor: 'default',
            marginRight: 4,
        }}>
            <div style={{ lineHeight: '0px' }}>
                {cloneElement(icon, {
                    fontSize: size,
                    color: color
                })}
            </div>
            <div
                style={{
                    ...extraIconStyle,
                    bottom: position.includes('bottom') ? '-4px' : null,
                    top: position.includes('top') ? '-4px' : null,
                    left: position.includes('start') ? '-4px' : null,
                    right: position.includes('end') ? '-4px' : null,
                }}>
                {cloneElement(extraIcon, {
                    fontSize: size,
                    color: color,
                    style: {
                        color: '#ffffff',
                        padding: '2px',
                    }
                })}
            </div>
        </div>
    )
}

ComposedIcon.propTypes = {
    icon: PropTypes.element.isRequired,
    extraIcon: PropTypes.element.isRequired,
    size: PropTypes.oneOf(['inherit', 'default', 'small', 'large']),
    color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'action', 'error', 'disabled']),
    position: PropTypes.oneOf(['top-start', 'top-end', 'bottom-start', 'bottom-end']),
    disabled: PropTypes.bool,
};

export default ComposedIcon;