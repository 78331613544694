import React, { Children, ReactNode } from 'react';
import PropTypes from 'prop-types';

import { Box, Tooltip } from '@mui/material';

import {
    useRecordContext,
    usePermissions,
    useTranslate,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { Utils } from 'admin/core';
import { GetCommonButtonInfo } from './Buttons';

interface Props {
    children?: ReactNode[] | null;
    disabled?: boolean;
    onlyIcons?: boolean;
}

export type ListRowActionsProps = Props;

const ListRowActions = (props: ListRowActionsProps) => {
    const {
        children = [],
        disabled = false,
        onlyIcons = true
    } = props;

    const { permissions } = usePermissions();
    const record = useRecordContext();
    const translate = useTranslate();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                borderRadius: 1,
            }}
        >
            {Children.map(children, (child: any, index) => {
                let typeName = (child.type || { name: null })?.name;

                if (!typeName)
                    typeName = child.type?.type?.name;

                const { disabled: disabledChild, onlyIcons: onlyIconsChild, label, ...childProps } = child.props

                const commonProps = {
                    key: index,
                    context: 'button',
                    disabled: disabled ||
                        (permissions.toLowerCase() === "user" && typeName === "DeleteWithConfirmButton") ||
                        (disabledChild !== null && Utils.IsFunction(disabledChild) && disabledChild(record, permissions)) ||
                        (Utils.IsBoolean(disabledChild) && disabledChild)
                }

                const buttonInfo = GetCommonButtonInfo(typeName, commonProps.disabled);

                if (onlyIcons || onlyIconsChild) {
                    const title = label ? translate(label) : buttonInfo.label;
                    if (title) {
                        return (
                            <Tooltip title={title}>
                                <Box>
                                    {Utils.CreateOrCloneElement(child, { ...commonProps, ...childProps, label: "", style: { color: buttonInfo.iconColor } })}
                                </Box>
                            </Tooltip>
                        )
                    }
                }

                return Utils.CreateOrCloneElement(child, { ...commonProps, ...childProps })
            })}
        </Box>
    )
};

ListRowActions.propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    onlyIcons: PropTypes.bool,
};

ListRowActions.displayName = 'ListRowActions';

export default ListRowActions;