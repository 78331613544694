import React, { useMemo, useState } from 'react';

import { Grid, Divider } from '@mui/material';

import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    required,
    email,
} from 'react-admin';

import { CreateActions, CreateToolbar, FileInput } from 'admin/components';

import { transformData } from './transformData';

const CustomCreate = () => {
    const defaultValues = useMemo(() => ({ lastName: '', providerType: 'Local', profilePhoto: null, defaultCulture: 'it-IT', status: 'Active' }), []);

    return (
        <Create redirect="list" actions={<CreateActions />} transform={transformData} queryOptions={{ meta: { expand: 'profilePhoto' } }}>
            <SimpleForm toolbar={<CreateToolbar />} defaultValues={defaultValues} >
                <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} sm={3} display="flex" justifyContent="center" alignItems="center" sx={{ padding: theme => theme.spacing(2), }}>
                        <FileInput source="profilePhotoUrl" useMediaGallery fullWidth />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextInput source="firstName" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput source="lastName" validate={required()} fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextInput source="userName" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput source="email" type="email" validate={[required(), email()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput source="phoneNumber" fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <SelectInput source="defaultCulture" validate={required()} fullWidth choices={[
                            { id: 'it-IT', name: 'pos.languages.it' },
                            { id: 'en-US', name: 'pos.languages.en' }
                        ]} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ReferenceInput source="status" reference="api/enums/userStatus" validate={required()}>
                            <SelectInput optionText="value" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider flexItem />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <ReferenceArrayInput source="rolesIds" reference="roles" >
                            <SelectArrayInput optionText="name" validate={required()} fullWidth />
                        </ReferenceArrayInput>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default CustomCreate;
