import React, { cloneElement, isValidElement, memo } from 'react';
import PropTypes from 'prop-types';

import {
    SaveButton,
    DeleteButton,
    Toolbar,
    useEditContext,
    useNotify
} from 'react-admin'; // eslint-disable-line import/no-unresolved

const EditToolbar = memo(
    (props) => {
        const { hasDelete, customActions, ...rest } = props;

        const notify = useNotify();
        const editProps = useEditContext(props);

        const onSuccess = (response) => {
            notify('ra.notification.deleted', {
                type: 'info',
                messageArgs: { smart_count: 1 },
                undoable: true,
            });
        };

        return (
            <Toolbar {...rest} style={{ justifyContent: 'end' }}>
                <SaveButton />
                {hasDelete && <DeleteButton mutationOptions={{ onSuccess }} disabled={editProps.isLoading} />}
                {customActions.map((customAction, key) => isValidElement(customAction) && cloneElement(customAction, {
                    ...editProps,
                    key: key,
                    disabled: editProps.isLoading,
                    context: 'button',
                }))}
            </Toolbar>
        )
    });

EditToolbar.propTypes = {
    hasDelete: PropTypes.bool,
    customActions: PropTypes.arrayOf(PropTypes.element)
};

EditToolbar.defaultProps = {
    hasDelete: false,
    customActions: [],
};

EditToolbar.displayName = 'EditToolbar';

export default EditToolbar;