import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';

import {
    useNotify,
    useLocaleState,
    useDataProvider,
    useTranslate,
    LinearProgress
} from 'react-admin';

import { Utils } from 'admin/core';

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Button,
    Grid,
    Tabs,
    Tab,
    Typography
} from '@mui/material';

import Dialog from './Dialog';
import { MediaUploader } from './MediaUploader';

import { makeStyles } from "@mui/styles";
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        width: '70vw'
    }
}));

const useStylesMediaPoster = makeStyles({
    root: {
        display: 'inline-block',
        width: '100%',
        marginTop: '1em',
        zIndex: 2,
        '&.selected': {
            border: '1px solid #999999',
            backgroundColor: '#dbdbdb',
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 4,
        '&:last-child': { padding: 0 }
    },
    media: {
        width: 'initial',
        height: 75,
        backgroundSize: 'contain',
        borderBottom: '1px solid #e0e0e3',
    },
});

const MediaPoster = (props) => {
    const [locale, setLocale] = useLocaleState();
    const { media, onClick = null, className, selected } = props;
    const { src, title, size, updatedAt } = media || {};
    const classes = useStylesMediaPoster();

    if (!src) return null;

    return (
        <Card className={classNames(classes.root, selected ? 'selected' : '')}>
            <CardActionArea onClick={onClick}>
                <CardMedia
                    className={classNames(classes.media, className)}
                    image={src}
                    title={title}
                />
                <CardContent className={classes.content}>
                    <Typography gutterBottom variant="body2" component="p">
                        {title}
                    </Typography>
                    <Typography gutterBottom variant="body2" component="p">
                        {Utils.FormatBytes(size)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {Utils.FormatToDate(updatedAt, locale) || null}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const MediaGallery = ({ open, setOpen, onSelected, accept, multiple, ...props }) => {
    const classes = useStyles();
    const mediaUploaderRef = useRef();

    const [activeTab, setActiveTab] = useState(0);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [medias, setMedias] = useState([]);
    const [innerOpen, setInnerOpen] = useState(open);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [mediaUploaderHasFiles, setMediaUploaderHasFiles] = useState(false);
    const translate = useTranslate();

    useEffect(() => {
        setInnerOpen(open);
        if (open && activeTab === 0) {
            setSelectedMedia(null);
            setLoading(true);

            loadMedias();

            setLoading(false);
        }
    }, [open]);

    const loadMedias = () => {
        dataProvider.getList(`medias`, {
            pagination: { page: 1, perPage: 10 },
            sort: { field: "updatedAt", order: "DESC" },
        }).then((response) => {
            const { data } = response || { data: [] };
            setMedias(data);
        }).catch(error => {
            console.error(error);
            notify('Error', 'warning')
            setMedias([]);
        });
    }

    if (loading) { return <LinearProgress />; }

    const onSelectMedia = (media) => {
        setSelectedMedia(media)
    };

    const onConfirm = async (e) => {
        e.stopPropagation();

        if (activeTab === 0) {
            if (onSelected && Utils.IsFunction(onSelected)) {
                onSelected(selectedMedia.src, selectedMedia);
                setOpen(false);
            }
        }
        else if (activeTab === 1) {
            await mediaUploaderRef.current.uploadMediaAsync();
            setActiveTab(0);
            loadMedias();
        }
    }

    const onMediaUploaderSelected = (files) => {
        setMediaUploaderHasFiles(files && files.length > 0);
    }

    const handleChangeTab = (event, newValue) => {
        setSelectedMedia(null)
        setActiveTab(newValue);
    };

    const actions = [
        <Button onClick={onConfirm} color="primary" disabled={!(selectedMedia || mediaUploaderHasFiles)}>
            {translate('ra.action.confirm')}
        </Button>
    ];

    return (
        <Dialog open={innerOpen} setOpen={setOpen} title={"pos.media_manager.title"} actions={actions}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={handleChangeTab}>
                        <Tab label={translate('pos.media_manager.tab1')} id='tab-0' />
                        <Tab label={translate('pos.media_manager.tab2')} id='tab-1' />
                    </Tabs>
                </Box>
                <TabPanel value={activeTab} index={0}>
                    <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2} className={classes.root}>
                        {
                            medias.map((media, key) => {
                                return (
                                    <Grid key={key} item xs={12} sm={2}>
                                        <MediaPoster media={media} onClick={() => onSelectMedia(media)} selected={selectedMedia && media.id === selectedMedia.id} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <MediaUploader ref={mediaUploaderRef} onSelected={onMediaUploaderSelected} {...props} />
                </TabPanel>
            </Box>
        </Dialog>
    );
};

MediaGallery.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onSelected: function (srcs, files) { },
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    multiple: PropTypes.bool,
};

MediaGallery.defaultProps = {
    open: false,
    accept: 'image/*',
    multiple: false,
}

export default MediaGallery;