import React, { Children, ReactNode } from 'react';
import PropTypes from 'prop-types';

import {
    TopToolbar,
    Button,
    CreateButton,
    ExportButton,
    useListContext,
    usePermissions,
    useRedirect,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { Utils } from 'admin/core';

import SortIcon from '@mui/icons-material/Sort';

interface Props {
    className?: string,
    children?: ReactNode[] | null;
    disabled?: boolean;
    hasCreate?: boolean;
    hasExport?: boolean;
    hasSort?: boolean;
    maxResults?: number;
    filters?: ReactNode | null;
}

export type ListActionsProps = Props;

const ListActions = (props: ListActionsProps) => {
    const {
        className = "",
        disabled = false,
        hasCreate = true,
        hasExport = true,
        hasSort = false,
        children = [],
        maxResults = 1000,
        filters = null,
    } = props;

    const { permissions } = usePermissions();
    const redirect = useRedirect();
    const {
        total,
        isLoading,
        sort,
        filterValues,
        displayedFilters,
        showFilter,
        resource,
    } = useListContext();

    return (
        <TopToolbar className={className}>
            {
                filters && Utils.CreateOrCloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })
            }
            {hasCreate && <CreateButton />}
            {hasExport && <ExportButton disabled={total === 0} resource={resource} sort={sort} filterValues={filterValues} maxResults={maxResults} />}
            {
                hasSort && <Button
                    onClick={() => { redirect('list', `${resource}`, undefined, { mode: 'sortableList' }); }}
                    label='ra.action.mode.sortableList'
                >
                    <SortIcon />
                </Button>
            }
            {Children.map(children, (child: any, index) => {
                const { disabled: disabledChild, ...childProps } = child.props

                const commonProps = {
                    key: index,
                    context: 'button',
                    disabled: disabled ||
                        isLoading ||
                        (disabledChild !== null && Utils.IsFunction(disabledChild) && disabledChild(total, isLoading, permissions)) ||
                        (Utils.IsBoolean(disabledChild) && disabledChild)
                }

                return Utils.CreateOrCloneElement(child, { ...commonProps, ...childProps })
            })}
        </TopToolbar>
    )
};

ListActions.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    hasCreate: PropTypes.bool,
    hasExport: PropTypes.bool,
    hasSort: PropTypes.bool,
    children: PropTypes.node,
    maxResults: PropTypes.number,
    filters: PropTypes.node,
};

ListActions.displayName = 'ListActions';

export default ListActions;