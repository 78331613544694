import React, { forwardRef, useImperativeHandle, ReactNode, useState } from 'react';
import { ReactElement } from 'react';

import Icon from '@mui/icons-material/Add';

import {
    Button,
    ButtonProps,
    useTranslate,
} from 'react-admin';

import { Dialog } from 'admin/components';

export interface Actions {
    open(): void;
    close(): void;
    isOpen?: boolean;
}

export type DialogButtonActions = Actions;

interface Props {
    icon?: ReactElement;
    label?: string;
    dialogTitle?: string;
    onOpen?: () => void;
    onClosed?: () => void;
    children?: ReactNode | null;
    fullScreen?: boolean;
    fullWidth?: boolean;
    scroll?: 'body' | 'paper';
    hideClose?: boolean;
    open?: boolean;
    preventEscapeKeyDown?: boolean;
    preventBackdropClick?: boolean;
}

export type DialogButtonProps = Props & ButtonProps;

const DialogButton = forwardRef<DialogButtonActions, DialogButtonProps>((props: DialogButtonProps, ref) => {
    const {
        icon = defaultIcon,
        label = 'ra.action.open',
        dialogTitle = '',
        onOpen = () => { },
        onClosed = () => { },
        children,
        fullScreen,
        fullWidth,
        scroll,
        hideClose = false,
        preventEscapeKeyDown = false,
        preventBackdropClick = false,
        open = false,
        ...rest
    } = props;
    const translate = useTranslate();
    const [innerOpen, setInnerOpen] = useState<boolean>(open);

    useImperativeHandle(
        ref,
        () => ({
            open() {
                setInnerOpen(true);
            },
            close() {
                setInnerOpen(false);
            },
            isOpen: innerOpen
        }));

    const handleOpen = () => {
        if (!innerOpen) {
            setInnerOpen(true);
            onOpen();
        }
    };

    const handleClose = () => {
        if (innerOpen) {
            setInnerOpen(false);
            onClosed();
        }
    };

    const dialogOptions = {
        fullScreen,
        fullWidth,
        scroll,
        hideClose,
        preventEscapeKeyDown,
        preventBackdropClick,
    };

    return (
        <>
            <Button label={label} onClick={handleOpen} {...(rest as any)}>
                {icon}
            </Button>
            <Dialog open={innerOpen} onOpen={handleOpen} onClosed={handleClose} title={translate(dialogTitle)} {...dialogOptions}>
                {children ? children : <></>}
            </Dialog>
        </>
    );
});

const defaultIcon = <Icon />;

export default DialogButton;
