import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Icon from '@mui/icons-material/OpenInBrowser';
import {
    Button,
    ButtonProps,
    useRecordContext
} from 'react-admin';
import { blue } from '@mui/material/colors';

interface Props {
    className?: string;
    disabled?: boolean;
    icon?: ReactElement;
    invalid?: boolean;
    label?: string;
    variant?: string;
}

export type OpenButtonProps = Props &
    ButtonProps & {
        alwaysEnable?: boolean;
    };

export const OpenButton = (
    props: OpenButtonProps
) => {
    const record = useRecordContext();

    const {
        icon = defaultIcon,
        label = '',
        onClick,
        className,
        ...rest
    } = props;


    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        async event => {
            event.stopPropagation();
            window.open(
                `${window.location.origin}/${record.id}`,
                '_blank'
            );
        },
        [onClick]
    );

    return (
        <StyledButton
            label={label}
            onClick={handleClick}
            className={clsx(BackButtonClasses.root, className)}
            {...(rest as any)}
            sx={{ color: blue[600] }}
        >
            {icon}
        </StyledButton>
    );
};

const defaultIcon = <Icon />;

OpenButton.propTypes = {
    className: PropTypes.string,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    icon: PropTypes.element,
};

const PREFIX = 'OpenButton';

export const BackButtonClasses = {
    root: `${PREFIX}-root`,
};

const StyledButton = styled(Button, {
    name: PREFIX,
    overridesResolver: (_props, styles) => styles.root,
})({});